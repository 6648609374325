import { getConfigs } from '../config';
const config = getConfigs();

export const routesConfig = {
  home: '/',
  nonServiceable: '/non-serviceable',
  results: '/results',
  thanks: '/thanks',
  business: '/business-internet',
  vas: '/vas',
  wirelessThanks: '/wireless-thanks',
  details: '/details',
  products: '/products',
  checkout: '/checkout',
  reviewOrder: '/review-order', // cart last step ordersubmit button one
  fwCheckout: '/fw-checkout',
  register: '/enter-details',
  vasPage: '/vasPage',
  scheduleInstallation: '/scheduleInstallation'
}
export const ATT_VENDORS = ['att', 'at&t'];

export const VENDORS = [
  'Lumen',
  'Frontier',
  'CenturyLink IMA',
  'CenturyLink',
];

export const OrderStatus = {
  newSession: 'session-started',
  acctEmail: 'acct-email:in-progress',
  addedToCart: 'added-to-cart',
  basicInfo: 'basic-info:in-progress',
  ccInfo: 'cc-info:in-progress',
  orderError: 'order-error/abandoned',
  orderSubmit: 'order-submit:complete',
  tcDeclined: 'tcDeclined',
  vasDeclined: 'vas-declined',
  vasAdded: 'vas-added',
  unserviceable: 'unserviceable',
  serviceCheck: 'serviceable',
}

export const billingPeriod = {
  yearly: '/yr',
  monthly: ' / mo',
  daily: '/day'
}

export const chasePaymentUrls = {
  getKey: config.CHASEPAYMENT_URL_GETKEY || 'n/a',
  encryption: config.CHASEPAYMENT_URL_ENCRYPTION || 'n/a'
}

export const reactAppVersion = config.APP_VERSION || '1.0';

export const nbcScoreTfn = {
  A: '8336015747',
  B: '8336015748',
  C: '8336015749',
  D: '8336015750',
  E: '8336015751',
  ABNDCART: '8336015752',
  NA: '8663833080'
}

export const WHI_TFN = '8336442512';
export const WHI_ABND_TFN = '8336442513';

export const MOBILE_SALES_TFN = '8336442510';
export const MOBILE_ABND_TFN = '8336442511';

export const vasLearnMoreLinks = {
  protect: 'cyber-safety/#prices',
  guardian: 'parental-control/',
  backup: 'data-backup/#onlinebackup',
}

export const vasProductDescription = {
  Protect: 'EarthLink Protect, powered by Norton, helps detect threats to your devices, online privacy and personal information on the dark web as you surf, bank, socialize and shop online. Device Security & Online Privacy.',
  ProtectPlus: 'EarthLink Protect+, powered by Norton, is designed to bring you comprehensive all-in-one protection for your connected devices, online privacy and identity.  The all-in-one cybersecurity solution for your life in the connected world.',
  Backup: 'Automatically backup files to prevent data loss and easily restore and share data.',
  Guardian: 'EarthLink Guardian, powered by Bark, is an award-winning monitoring and screen time management tool that keeps kids safer online while preserving their privacy.',
  EasyTech: 'EarthLink EasyTech, gives unlimited remote support from EarthLink technical experts for an unlimited number of devices, safely and securely.',
}

export const validVasproducts = ['126', '127', '128', '129', '130', '131', '132', '133', '410', '420', '421', '422','430','431','432'];

export const validMobileproducts = ['504', '503', '502', '501', '500'];

export const SecQueOptions = [
  'What was the make of your first car?',
  'What is the name of your first pet?',
  'What is your favorite movie?',
  'What is your favorite vacation spot?',
  'What is your maternal grandfather\'s first name?',
  'What city does your nearest sibling live in?',
]

export const cardErrorModalMessage = {
  'actionDisplayErrorModal': 'Sorry, we were unable to authorize your Credit Card. Would you like to try a different Credit Card?',
  'actionDisplayPrepaidModal': 'Sorry, Prepaid Cards are not allowed.  Please try another Card.',
  'actionDisplayFloorModal': 'Sorry, we were unable to authorize your card for today\'s amount.  Please try another Card.',
}

export const vasProductTermsSpanish = [
  { heading: 'EasyTech', serviceTerm: '' },

  { heading: 'Protect+ Essential', serviceTerm: 'Este plan protege contra los virus y el malware para hasta 10 computadoras personales (PCs), computadoras MAC, dispositivos móviles o tabletas y incluye la Promesa de Protección de Virus, una red privada virtual con almacenamiento en la nube de 100 GB hasta 500 MB (basado en el plan seleccionado), mantenimiento de contraseña y control parental. Reembolso y compensación de gastos, cada uno con limites de hasta $1 millón por el plan Premio Plus, hasta $100,000 por el plan Premio y hasta $25,000 por el plan Esencial. Si es necesario, hasta $1 millón para contratar abogados y expertos para ayudar resolver su caso de robo de identidad. Nadie puede prevenir todos los delitos cibernéticos o todos los robos de identidad Los términos de Política, las condiciones y las exclusiones se encuentran en: lifelock.com/legal' },
  { heading: 'Protect+ Premium', serviceTerm: 'Este plan protege contra los virus y el malware para hasta 10 computadoras personales (PCs), computadoras MAC, dispositivos móviles o tabletas y incluye la Promesa de Protección de Virus, una red privada virtual con almacenamiento en la nube de 100 GB hasta 500 MB (basado en el plan seleccionado), mantenimiento de contraseña y control parental. Reembolso y compensación de gastos, cada uno con limites de hasta $1 millón por el plan Premio Plus, hasta $100,000 por el plan Premio y hasta $25,000 por el plan Esencial. Si es necesario, hasta $1 millón para contratar abogados y expertos para ayudar resolver su caso de robo de identidad. Nadie puede prevenir todos los delitos cibernéticos o todos los robos de identidad Los términos de Política, las condiciones y las exclusiones se encuentran en: lifelock.com/legal' },
  { heading: 'Protect+ Premium Plus', serviceTerm: 'Este plan protege contra los virus y el malware para hasta 10 computadoras personales (PCs), computadoras MAC, dispositivos móviles o tabletas y incluye la Promesa de Protección de Virus, una red privada virtual con almacenamiento en la nube de 100 GB hasta 500 MB (basado en el plan seleccionado), mantenimiento de contraseña y control parental. Reembolso y compensación de gastos, cada uno con limites de hasta $1 millón por el plan Premio Plus, hasta $100,000 por el plan Premio y hasta $25,000 por el plan Esencial. Si es necesario, hasta $1 millón para contratar abogados y expertos para ayudar resolver su caso de robo de identidad. Nadie puede prevenir todos los delitos cibernéticos o todos los robos de identidad Los términos de Política, las condiciones y las exclusiones se encuentran en: lifelock.com/legal' },
  { heading: 'EarthLink Protect 5 Device', serviceTerm: 'Este plan protege contra los virus y el malware para hasta 10 computadoras personales (PCs), computadoras MAC, dispositivos móviles o tabletas y incluye la Promesa de Protección de Virus, una red privada virtual con almacenamiento en la nube de 100 GB hasta 500 MB (basado en el plan seleccionado), mantenimiento de contraseña y control parental. Nadie puede prevenir todos los delitos cibernéticos o todos los robos de identidad Los términos de Política, las condiciones y las exclusiones se encuentran en: lifelock.com/legal Los planes que monitorean la red negra (el Dark Web) y que son impulsadas por LifeLock en los planes de Norton 360 (sin LifeLock) solo monitorean su correo electrónico. Las funciones de control parental de Norton no están disponibles para las computadoras Mac.' },
  { heading: 'EarthLink Protect 10 Device', serviceTerm: 'Este plan protege contra los virus y el malware para hasta 10 computadoras personales (PCs), computadoras MAC, dispositivos móviles o tabletas y incluye la Promesa de Protección de Virus, una red privada virtual con almacenamiento en la nube de 100 GB hasta 500 MB (basado en el plan seleccionado), mantenimiento de contraseña y control parental. Nadie puede prevenir todos los delitos cibernéticos o todos los robos de identidad Los términos de Política, las condiciones y las exclusiones se encuentran en: lifelock.com/legal Los planes que monitorean la red negra (el Dark Web) y que son impulsadas por LifeLock en los planes de Norton 360 (sin LifeLock) solo monitorean su correo electrónico. Las funciones de control parental de Norton no están disponibles para las computadoras Mac.' },
  { heading: 'Online Backup 50GB', serviceTerm: '' },
  { heading: 'Online Backup 250GB', serviceTerm: '' },
  { heading: 'Online Backup 500GB', serviceTerm: '' },
  { heading: 'Guardian', serviceTerm: 'Tienes que tener por lo menos 18 años para ordenar y recibir el servicio de EarthLink Guardian.' },
  { heading: 'EarthLink Live TV Mas',serviceTerm:'EarthLink TV y el contenido brindado a través de este servicio es para uso exclusive personal y dentro de los Estados Unidos y sus territorios. Se permite el uso de 3 dispositivos simultaneos. Usted es responsable por el acceso al internet y sus cargos. Los canales locales están sujetos a disponibilidad según el mercado con algunos eventos de deportes restringidos.'},
  { heading: 'EarthLink Live TV Premium',serviceTerm:'EarthLink TV y el contenido brindado a través de este servicio es para uso exclusive personal y dentro de los Estados Unidos y sus territorios. Se permite el uso de 3 dispositivos simultaneos. Usted es responsable por el acceso al internet y sus cargos. Los canales locales están sujetos a disponibilidad según el mercado con algunos eventos de deportes restringidos.'},
  { heading: 'EarthLink Live TV Plus',serviceTerm:'EarthLink TV y el contenido brindado a través de este servicio es para uso exclusive personal y dentro de los Estados Unidos y sus territorios. Se permite el uso de 3 dispositivos simultaneos. Usted es responsable por el acceso al internet y sus cargos. Los canales locales están sujetos a disponibilidad según el mercado con algunos eventos de deportes restringidos.'}
]

export const referrerUris = {
  'earthlink.net': ['866-618-0488', '866-618-0489', '833-704-1238'],
  'earthlink.net.internet': ['866-769-0188', '866-769-0656', '833-704-2543'],
  'earthlink.net.internet.fiber-internet': ['866-769-8738', '866-885-5638', '833-704-2546'],
  'earthlink.net.internet.home-internet': ['866-900-9434', '866-903-3132', '833-704-2571'],
  'earthlink.net.internet.rural-internet': ['866-913-9434', '877-462-2831', '833-704-2584']
}
