import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { v4 as uuid } from 'uuid';
import { leftQuotes, rightQuotes } from '../../../images/';
const reviewCarousel = ({ showCallNow }) => {
  const reviewSlideClass = `review-slide ${!showCallNow ? 'expanded' : ''}`;
  const quotesRightContainer = `right-quotes-container ${
    !showCallNow ? 'adjusted-right' : ''
  }`;
  const quotesLeftContainer = `left-quotes-container ${
    !showCallNow ? 'adjusted-left' : ''
  }`;
  const review = [
    {
      content:
        '<p><em><strong>I have had highspeed internet access from EarthLink for a few years now. Never experienced any issue. Have not had to make any customer service calls.</strong></em></p>\n',
    },
    {
      content:
        '<p><em><strong>When everything works perfectly, as it does most always with my EarthLink account, I&#8217;m very happy and have been for 22 years of using EarthLink. But when you need help, that&#8217;s when EarthLink really shines. Recently I needed help and Brian took my call. He guided through a new way for me to solve my issue. He stayed with me until I fully understood what to do and tested it a few times.</strong></em></p>\n',
    },
    {
      content:
        '<p>I am very happy with my EarthLink internet. AT&amp;T was going to raise my prices because I had been with them for a year so I switched my highspeed internet to EarthLink. It was installed in February and I have been happy with it since. I did have an issue with one smart TV furthest from my modem freezing up. I called up customer service, they ran some tests, then sent me a WIFI extender that resolved my issues and my internet is working better than when I was with AT&amp;T. Service I never received from AT&amp;T. <strong>Thank you EarthLink for the great service!</strong></p>\n',
    },
    {
      content:
        '<p>I have had EarthLink internet one year so far. I have had zero problems with them as a provider. Stellar clarity and speed in connectivity. <strong>The customer service experience was nothing but pleasant and helpful.</strong></p>\n',
    },
    {
      content:
        '<p>EarthLink is a good company to work with. When there&#8217;s a problem, they take the time to figure out what&#8217;s wrong, fix it, and make sure you&#8217;re <strong>100% satisfied</strong>.</p>\n',
    },
    {
      content:
        '<p><strong>I really enjoy having EarthLink as my internet provider.</strong> The service keeps up with the multiple devices that are being used throughout the house, with very few outages or lags.</p>\n',
    },
    {
      content:
        '<p>I like the way EarthLink gets right on your problem. The customer service reps <strong>are very polite and kind.</strong></p>\n',
    },
    {
      content:
        '<p>They have a reliable service at a good price, are always dependable, and have a good site.</p>\n',
    },
    {
      content:
        '<p>It was easy to install the service. I have been able to get answers to my questions in a reasonable amount of time.</p>\n',
    },
    {
      content:
        '<p>They were very quick and efficient in getting me connected to the internet. I haven&#8217;t had any issues with them. I&#8217;m very satisfied with their service.</p>\n',
    },
    {
      content:
        '<p>I have used EarthLink for about 15 years and have had few problems. When you call they are responsive.</p>\n',
    },
    {
      content:
        '<p>I really love them. They have great prices, and they work hard to try and fix any issues that you are having.</p>\n',
    },
    {
      content:
        '<p>I like the fast internet, it really helps me do research online. I wouldn&#8217;t change anything about the service. The service is fast and reliable. I&#8217;m satisfied with my partnership with EarthLink.</p>\n',
    },
    {
      content:
        '<p>They were very quick and efficient in getting me connected to the internet. I haven&#8217;t had any issues with them. I&#8217;m very satisfied with their service.</p>\n',
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    dotsClass: 'dot__bar',
  };
  return (
    <div className="review-carousel">
      <h2>Here’s what your neighbors are saying about us</h2>
      <div className="slider-container">
        <div
          className="inner-container"
          style={{ width: showCallNow ? '770px' : '600px' }}
        >
          <div className={quotesLeftContainer}>
            <img src={leftQuotes} alt="left-quotes"></img>
          </div>
          <Slider {...settings}>
            {
              review?.map((item) => (
                <div key={uuid()} className={reviewSlideClass}>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              ))}
          </Slider>
          <div className={quotesRightContainer}>
            <img src={rightQuotes} alt="right-quotes"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reviewCarousel;
