import React from 'react';
import PropTypes from 'prop-types';
// Example data structure
const SpeedCard = ({ speed }) => {
  const getSpeedDetails = (speedValue) => {
    if (speedValue <= 300) {
      return { label: 'FAST', fillLevel: 2 };
    } else if (speedValue <= 1000) {
      return { label: 'FASTER', fillLevel: 3 };
    } else {
      return { label: 'FASTEST', fillLevel: 4 };
    }
  };

  const { label, fillLevel } = getSpeedDetails(speed ?? 0);

  const renderSpeedIcons = (fillCount) => {
    const icons = [];
    for (let i = 0; i < 4; i++) {
      icons.push(
        <span key={i} className={`icon ${i < fillCount ? 'filled' : ''}`}></span>
      );
    }
    return icons;
  };

  return (
    <div className="speed-card">
      <div className="speed-card-content">
        <div className={`speed-label ${label?.toLowerCase()}`}>{label}</div>
        <div className="speed-icons">{renderSpeedIcons(fillLevel ?? 0)}</div>
      </div>
    </div>
  );
};
SpeedCard.propTypes = {
  speed: PropTypes.number.isRequired,
};


export default SpeedCard;
