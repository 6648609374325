import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import promise from 'redux-promise-middleware'

const enhancer = composeWithDevTools(applyMiddleware(thunk, promise));

const store = createStore(rootReducer, enhancer);

export default store;
