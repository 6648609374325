import axios from 'axios';
import {getConfigs} from '../config';
import store from '../store';
import { updateTokens } from '../store/action/OrderAction';
import { isUndefinedNullOrEmpty } from '../common/common';
import { getAccessToken, getRefreshToken } from '../common/common';
import { _xn23 } from '../nonce';
import { getURI } from '../helpers/OrderHelperFunctions';

const TOKEN_MISSING = 'Identity token is missing';
const TOKEN_INVALID = 'Identity token is invalid';
const config = getConfigs();
export const api = {
  axios: axios.create()
}
api.axios.interceptors.request.use(async (request) => {
  const authToken = getAccessToken();
  const token = authToken || '';
  const nonceHeader =
    typeof window !== 'undefined'
      ? await _xn23(getURI(request.url))
      : undefined;
  const headersWithToken = token
    ? {
      ...request.headers,
      Authorization: token,
    }
    : request.headers;
  headersWithToken['x-nonce'] = nonceHeader;
  return {
    ...request,
    headers: headersWithToken,
  };
});

api.axios.interceptors.response.use(response => {
  return response;
}, async function (error) {
  const originalRequest = error.config;
  // Logout if there is a 401 error that's not token related
  if (isUndefinedNullOrEmpty(error.response)) {
    // store.dispatch(logout());
    return Promise.reject(error);
  }
  // Handle 403 errors
  if (error.response.status === 403) {
    // store.dispatch(logout());
    return Promise.reject(error);
  }
  // Handle 401 errors
  if (error.response.status === 401 && !originalRequest._retry) {
    if (!isUndefinedNullOrEmpty(error.response.data.error)
      && (error.response.data.error === TOKEN_MISSING
        || error.response.data.error === TOKEN_INVALID)) {
      // store.dispatch(logout());
      return Promise.reject(error);
    } else {
      const refreshToken = getRefreshToken();
      originalRequest._retry = true;
      const nonceHeader =
        typeof window !== 'undefined'
          ? await _xn23(getURI(originalRequest.url))
          : undefined;
      const headersWithNonce = {
        ...originalRequest.headers,
        'x-nonce': nonceHeader,
      };
      return axios({
        method: 'POST',
        url: `${config.API_ENDPOINT}/refresh-token`,
        data: { refreshToken },
        headers: headersWithNonce
      }).then(res => {
        if (res.status === 201) {
          store.dispatch(updateTokens(res.data));
          return api.axios(originalRequest);
        }
      });
    }
  }
  throw error;
});
