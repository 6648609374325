import React, { useEffect } from 'react'
import { getBaseUrl } from '../../common/common'
import { useServiceablitityData } from '../../hooks/order';
import { arrow, ATImg, chevron, Cox, earthlinkLogo, Frontier, HighSpeedOptions, Hughes, kinetic, RiseBroadBand, Spectrum, tMobile, Viasat, xfinity } from '../../images'

const NonServiceable = () => {
  const { normalizedAddress } = useServiceablitityData();
  const redirectTo = `https://www.highspeedoptions.com/?zip=${normalizedAddress?.zipCode}&refdom=earthlink`;

  useEffect(() => {
    if (normalizedAddress?.zipCode) {
      const redirectTimer = setTimeout(() => {
        window.location.href = redirectTo;
      }, 5000); // 5000 milliseconds (5 seconds)

      return () => clearTimeout(redirectTimer);
    }
  }, []);


  return (
    <div>
      <div className="elink-container responsive-w-100 p-top-5 m-top-3 d-flex non-serv-div">
        <div className="non-serv">
          <div>
            <h2 className="non-serv-earthlink">
              EarthLink Fiber is not yet <br /> available at your address.
            </h2>
            <p style={{ fontSize: '1.2rem' }}>
              Through EarthLink Connections we can <br /> offer you other great deals.
            </p>
          </div>

          <div className="d-flex" style={{
            columnGap: '2rem',
            margin: '1.4rem 2rem',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <div className="logo">
              <a href={getBaseUrl()}>
                <img src={earthlinkLogo} />
              </a>
            </div>

            <div className="logo">
              <img src={arrow} />
            </div>

            <div className="logo">
              <a href="">
                <img src={HighSpeedOptions} />
              </a>
            </div>
          </div>

          <div style={{ fontSize: '1.1rem' }}>
            You will be redirected to <br /> {<a disabled={!normalizedAddress?.zipCode} href={`https://www.highspeedoptions.com/?zip=${normalizedAddress?.zipCode}&refdom=earthlink`} onClick={(e) => !normalizedAddress?.zipCode && e.preventDefault()}>highspeedoptions.com</a>} to find the best <br />
            fiber plans in your area.
          </div>

        </div>

        <div className="non-serv-icons">
          <img src={xfinity} />
          <img src={Frontier} />
          <img src={Hughes} />
          <img src={Viasat} />
          <img src={RiseBroadBand} />
          <img src={kinetic} />
          <img src={Cox} />
          <img src={tMobile} />
          <img src={ATImg} />
          <img src={Spectrum} />
        </div>

        <div style={{ textAlign: 'center' }}>
          <div>
            <p style={{ marginBottom: '30px' }}>
              You&apos;ll be redirected in 5 seconds.If not, click the button <br />
              below to find the best fiber plans in your area.
            </p>
          </div>

          <div className={normalizedAddress?.zipCode ? 'highspeed-btn' : 'highspeed-btn-disabled highspeed-btn'}>
            <button className={normalizedAddress?.zipCode ? 'non-serv-button' : 'non-serv-button highspeed-btn-disabled'} disabled={!normalizedAddress?.zipCode} onClick={() => {
            }}>
              Go to highspeedoptions.com{' '}
              <img src={chevron} alt="chevron" />
            </button>
          </div>
        </div>

      </div>

    </div>
  )
}

export default NonServiceable
