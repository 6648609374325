import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSessionData, useVasData } from '../../hooks/order';
import { OrderStatus, validVasproducts } from '../../util/util';
import { BackdropLoader } from '../common/Loader';
import queryString from 'query-string';
import { setExpressLoading, getExpressVasProducts, sessionCall, setElnkMailboxReq, getServicePropsByCode, saveVasPlanInfo, updateOrder, saveFirstStep } from '../../store/action/OrderAction';
import { redirectToSecurityTools, setPromoCode } from '../../common/common';
import Checkout from './Checkout/CheckOut';
import VasStatic from './VasStatic';
import useLeadsSDK from '../../hooks/useLeadsSDK';

const VasOnly = () => {
  const leads = useLeadsSDK();
  const dispatch = useDispatch();
  const qsServRef = window.location.hash.split('servRef=')[1] || '';
  const [redirectToVas, setRedirectToVas] = useState(false);
  const { sessionResponse, orderInfo } = useSessionData();
  const { vasError } = useVasData();
  const promoCode = sessionResponse && sessionResponse.promoCode ? sessionResponse.promoCode : null;
  const agentId = sessionResponse && sessionResponse.agentId ? sessionResponse.agentId : null;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!Object.keys(sessionResponse).length) {
      const gid = queryString.parse(window.location.search).gid;
      dispatch(sessionCall(gid)).then().catch(() => {
        dispatch(setExpressLoading(false));
        setRedirectToVas(true);
      });
    }
    if (!qsServRef || (qsServRef && !validVasproducts.includes(qsServRef))) {
      redirectToSecurityTools();
    }
    if (agentId) {
      setPromoCode(promoCode)
      dispatch(getExpressVasProducts(promoCode)).then(async (res) => {
        let itemFound = false;
        if (res?.value?.data && res.value.data.length > 0 && qsServRef) {
          res.value.data.map(async (item) => {
            if (item.servRef === qsServRef) {
              dispatch(saveFirstStep(true));
              itemFound = true;

              await dispatch(getServicePropsByCode(item?.servCode)).then((response) => {
                dispatch(setElnkMailboxReq(response.action?.payload?.data?.item?.isMboxReq));
              });

              dispatch(saveVasPlanInfo({
                vasPlan: [item],
              }));
              leads?.updateOrder?.('Update Order: Add Product', {
                products: [
                  {
                    data: {
                      category: 'vas',
                      servCode: item?.servCode,
                    },
                    id: String(item?.servRef),
                    name: item?.serviceName,
                    price: String(item?.price),
                    quantity: 1,
                  },
                ],
              });
            }
          });
          dispatch(updateOrder({ orderStatus: OrderStatus.vasAdded, serviceReference: qsServRef }, orderInfo.id));
          setLoading(false);
        }
        if (vasError || !itemFound) {
          setLoading(false);
          setRedirectToVas(true);
        }
      }).catch(() => {
        setLoading(false);
        setRedirectToVas(true);
      })
    }
  }, []);

  return (
    <>
      {(loading) &&
        <BackdropLoader />
      }
      {(redirectToVas && !loading) &&
        <VasStatic />
      }
      {!loading &&
        <Checkout />
      }
    </>
  )
}

export default VasOnly;
