import React from 'react';
import easyTech from '../../images/easy-tech.png';
import { Row, Col } from 'antd';

export const EasyTech = () => {
  return (
    <div className="protect-section border-same m-top-3 same-protect p-b-0 responsive-p-b-2">
      <div className="protect-title mt-1 mb-4">
        <h2>EasyTech</h2>
        <h4>
          Unlimited Remote Expert Tech Support for Your Home and Family
        </h4>
      </div>
      <Row className="m-top-2">
        <Col span={8}>
          <div className="col-lg-4 col-md-12">
            <div className="imageWrapper">
              <img src={easyTech} alt="easy tech" className="width-full-img" />
            </div>
          </div>
        </Col>
        <Col span={1}></Col>
        <Col span={15}>
          <div className="col-lg-8 col-md-12">
            <p>
              Whether working from home, learning at school or teaching others at home, your personal and home technology has never been more important. Computers, laptops, tablets, Smart TVs, entertainment systems, home automation and security systems – most technology available today now plays a critical role for your home and your family.
            </p>
            <p>
              With the increase in home tech comes an increase in the need for tech support. Luckily, EarthLink now offers a streamlined, remote, easy to access, safe and secure solution. With EarthLink EasyTech, unlimited expert technical advice and support for any number of devices is at hand.
            </p>
            <div className="contact-no-sec protect-contact">
              <a href="tel:844-660-1409" className="blue-button m-top-1"><i className="fa fa-phone"></i>Call 844-660-1409</a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EasyTech;
