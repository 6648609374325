import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useBuyFlow,
  useCartData,
  useCallCenterFlag,
  useServiceablitityData,
  useSessionData,
} from '../../../hooks/order';
import { Row, Col } from 'antd';
import InternetPlans from '../Products/InternetPlans';
import MobilePhonePlans from '../Products/MobilePhonePlans';
import {
  formatTFN,
  // getAggregatedAddress,
  getBaseUrl,
  getNbc,
  getTFN,
  vendorInformation,
} from '../../../common/common';
import { useDispatch } from 'react-redux';
import {
  getBundleDetails,
  saveFirstStep,
  saveInternetPlan,
  saveVasPlanInfo,
  saveWirelessPlanInfo,
  setSelectedBundle,
  setTFN,
  setActiveProductTab,
} from '../../../store/action/OrderAction';
import { useNavigate } from 'react-router-dom';
import { MOBILE_SALES_TFN, routesConfig, WHI_TFN } from '../../../util/util';
import { EECproductDetailView } from '../../../common/GTMScripts';
import useWindowDimensions from '../../common/useWindowDimensions';
import CartInformation from '../../common/CartInformation';
import moment from 'moment-timezone';
import BundlePlans from './BundlePlans';
moment.tz.setDefault('America/New_York');

function OrOperator(prop1, prop2){
  return prop1 || prop2
}

const Product = () => {
  const {
    extendedInfo,
    TFN,
    setActiveProductTab: setActiveProductTabValue,
  } = useSessionData();
  const { isMobile } = useWindowDimensions();
  const dispatch = useDispatch();
  const { showCallNow, showPromoStickers } = useCallCenterFlag();
  const navigate = useNavigate();
  const { buyflow } = useBuyFlow();
  const {
    isInternetAdded,
    isVasAdded,
    isMobileAdded,
    isBundleAdded,
    internetPlan,
    vasPlanInfo,
    wirelessCartInfo,
    updateSelectedBundle,
    servRef,
    isWhiAdded,
    isBundleVisible,
    getMobilePrice,
  } = useCartData();
  const { serviceabilityCheck, normalizedAddress } = useServiceablitityData();
  const {
    plans = [],
    mobileProducts = [],
    fixedWirelessProducts = [],
    bundles = [],
    vasProducts = [],
    bundleData = [],
  } = serviceabilityCheck;
  const [scroll, setScroll] = useState(false);
  const [bundlePrices, setBundlePrices] = useState([]);
  const [minPrices, setMinPrices] = useState({
    mobileProducts: '',
    internetProducts: '',
    bundles: '',
    overall: '',
  });
  const [check300, setCheck300] = useState(false);
  const showInternet =
    buyflow &&
    (OrOperator(buyflow === 'all',buyflow === 'internet')) &&
    OrOperator (plans.length > 0 , fixedWirelessProducts.length > 0);
  const activeTab = useMemo(() => {
    if (setActiveProductTabValue) {
      return setActiveProductTabValue;
    } else if (showInternet) {
      return 'internet';
    } else {
      return 'all';
    }
  }, [setActiveProductTabValue, showInternet]);
  const [currentTab, setCurrentTab] = useState(activeTab);
  const showMobile =
    buyflow &&
    (OrOperator(buyflow === 'all' , buyflow === 'wireless')) &&
    mobileProducts?.length > 0;
  const showBundle =
    buyflow &&
    (OrOperator(buyflow === 'all' , buyflow === 'bundle')) &&
    bundles?.length > 0;
  const showTab =false;

  const [tfn, setTfn] = useState(TFN ? formatTFN(TFN) : getTFN());

  const allProducts = [
    ...plans,
    ...mobileProducts,
    ...fixedWirelessProducts,
    ...vasProducts,
  ];

  useEffect(() => {
    if (isBundleVisible) {
      dispatch(getBundleDetails(bundles.map((el) => el.servRef).join(',')));
    }
  }, [bundles, isBundleVisible]);

  // const getBundlesMinPrice = useMemo(() => {
  //   const bundleMinPrices =
  //     bundleData?.map((bundle) => {
  //       const productPrice = [1, 2, 3, 4].reduce((price, key) => {
  //         if (!bundle[`servCode${key}`] || !bundle[`servLevel${key}`]) {
  //           return price;
  //         }
  //         if (bundle[`servLevel${key}`] === '*') {
  //           return (
  //             price +
  //             allProducts.reduce((minPrice, prod) => {
  //               if (
  //                 prod?.servCode?.toLowerCase() ===
  //                   bundle[`servCode${key}`]?.toLowerCase() &&
  //                 prod?.servLevel
  //               ) {
  //                 const totalPrice =
  //                   Number(prod.price) + (Number(prod?.equipmentCost) || 0);
  //                 return !minPrice || totalPrice < minPrice
  //                   ? totalPrice
  //                   : minPrice;
  //               }
  //               return minPrice;
  //             }, 0)
  //           );
  //         }
  //         const currentProduct = allProducts.find(
  //           (product) =>
  //             product.servLevel?.toLowerCase() ===
  //             bundle[`servLevel${key}`]?.toLowerCase()
  //         );
  //         return currentProduct
  //           ? price +
  //               (Number(currentProduct.price) || 0) +
  //               (Number(currentProduct.equipmentCost) || 0)
  //           : price;
  //       }, 0);

  //       return (Number(productPrice) + Number(bundle.creditAmount)).toFixed(2);
  //     }) || [];

  //   return bundleMinPrices.length ? Math.min(...bundleMinPrices) : 0;
  // }, [allProducts.length, bundleData.length]);

  const checkProductSpd = () => {
    if (plans?.length > 0) {
      plans.map((product) => {
        const { downstreamSpd = 0 } = product;
        const productSpd = downstreamSpd / 1000 === 300;
        const vendor = vendorInformation(product, extendedInfo);
        if (vendor.toLowerCase() === 'at&t' && productSpd) {
          return setCheck300(true);
        }
        return false;
      });
    }
  };

  useEffect(() => {
    if (
      !plans?.length &&
      !mobileProducts?.length &&
      !fixedWirelessProducts?.length
    ) {
      window.location.href = getBaseUrl();
    }
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 160);
    });
    dispatch(saveFirstStep(false));
    dispatch(saveVasPlanInfo({}));
    EECproductDetailView([...plans, ...mobileProducts]);
    // IN CASE ONLY WHI RETURNED, CHANGE HEADER TFN
    if (
      !plans?.length &&
      !mobileProducts?.length &&
      fixedWirelessProducts?.length
    ) {
      dispatch(setTFN(WHI_TFN));
    } else if (
      (OrOperator(!plans?.length &&
        mobileProducts?.length &&
        !fixedWirelessProducts?.length),
      buyflow === 'wireless')
    ) {
      dispatch(setTFN(MOBILE_SALES_TFN));
    } else {
      const nbcScore = getNbc();
      dispatch(setTFN(nbcScore));
    }
    checkProductSpd();
  }, []);

  useEffect(() => {
    if (TFN) {
      setTfn(formatTFN(TFN));
    }
  }, [TFN, window.location.href]);

  useEffect(() => {
    let minPricesObject = minPrices;

    if (!minPrices.mobileProducts) {
      const prices = mobileProducts.map((product) =>
        getMobilePrice(product, 'product')
      );
      const minPrice = Math.min(...prices);

      minPricesObject.mobileProducts = OrOperator(minPrice , '0.00')
    }
    if (!minPrices.internetProducts) {
      const prices = [...plans, ...fixedWirelessProducts].map(
        (product) => product.price
      );
      const minPrice = Math.min(...prices);

      minPricesObject.internetProducts = OrOperator(minPrice ,'0.00')
    }
    if (!minPrices.bundles && isBundleVisible) {
      minPricesObject.bundles =  '0.00';
    }
    setMinPrices(minPricesObject);
  }, [
    mobileProducts,
    plans,
    fixedWirelessProducts,
    isBundleVisible,
    bundleData.length,
  ]);

  useEffect(() => {
    if (minPrices.internetProducts && minPrices.mobileProducts) {
      let overallMinPrice = 0;
      if (minPrices?.bundles !== '') {
        overallMinPrice = Math.min(
          minPrices.internetProducts,
          minPrices.mobileProducts,
          minPrices?.bundles
        );
      } else {
        overallMinPrice = Math.min(
          minPrices.internetProducts,
          minPrices.mobileProducts
        );
      }
      setMinPrices({ ...minPrices, overall: OrOperator( overallMinPrice , '0.00' )})
    }
  }, [minPrices.internetProducts, minPrices.mobileProducts, minPrices.bundles]);

  useEffect(() => {
    updateSelectedBundle();
  }, [
    isInternetAdded,
    isMobileAdded,
    isVasAdded,
    isBundleAdded,
    bundleData,
    dispatch,
    internetPlan,
    wirelessCartInfo,
    vasPlanInfo,
    servRef,
    isWhiAdded,
  ]);

  const labelWithCharS = (val) => (val.length > 1 ? 's' : '');

  const planLabel = (val) => {
    if (!isMobile) {
      return 'Plan' + labelWithCharS(val);
    }
    return '';
  };

  const activeTabHandler = useCallback((val) => {
    setCurrentTab(val);
  }, []);

  const showTabClasses = () => {
    if (
      plans.length === 0 &&
      fixedWirelessProducts.length === 0 &&
      currentTab !== 'internet'
    ) {
      return 'mobileTop';
    } else  if (showCallNow) {
      return 'intTop responsive-width-set agent-full-width';
    } else {
      return 'intTop responsive-width-set';
    }
  };

  const getBundleLength = () => {
    if (isBundleVisible) {
      return bundleData?.length;
    }
    return 0;
  };

  const handleRestAddress = () => {
    navigate(routesConfig.home);
    dispatch(saveInternetPlan({}));
    dispatch(setSelectedBundle(null));
    dispatch(
      saveWirelessPlanInfo({
        wirelessPlanInfo: [],
        wirelesServeRef: '',
      })
    );
    dispatch(setActiveProductTab(''));
  };

  return (
    <>
      {/* <div className="elink-container p-top-5 height-big-screen"> */}
      <div
        className={`elink-container p-top-5 height-big-screen ${
          OrOperator( showCallNow , showPromoStickers) ? 'call_agent_online' : ''
        }`}
      >
        {showTab && (
          <div className={`filters-top ${scroll ? 'tabfixed' : ''}`}>
            <div className="elink-container">
              <Row
                className={`top-filter ${
                  !isBundleVisible ? 'without-bundle' : ''
                }`}
              >
                <Col span={6} onClick={() => activeTabHandler('all')}>
                  <div
                    className={`single-filter text-center ${
                      currentTab === 'all' && 'active-filter'
                    }`}
                  >
                    <h4>
                      All {isMobile ? '' : 'Plans'}{' '}
                      <span>
                        (
                        {plans?.length +
                          mobileProducts?.length +
                          fixedWirelessProducts?.length +
                          getBundleLength()}
                        )
                      </span>
                    </h4>
                    <p>
                      ${minPrices.overall} {isMobile ? '+' : 'and up'}{' '}
                    </p>
                  </div>
                </Col>
                <Col span={6} onClick={() => activeTabHandler('internet')}>
                  <div
                    className={`single-filter text-center ${
                      currentTab === 'internet' && 'active-filter'
                    }`}
                  >
                    <h4>
                      Internet {planLabel(plans)}{' '}
                      <span>
                        ({plans.length + fixedWirelessProducts.length})
                      </span>
                    </h4>
                    <p>
                      ${minPrices.internetProducts} {isMobile ? '+' : 'and up'}{' '}
                    </p>
                  </div>
                </Col>
                {mobileProducts?.length > 0 && (
                  <Col span={6} onClick={() => activeTabHandler('mobile')}>
                    <div
                      className={`single-filter text-center ${
                        currentTab === 'mobile' && 'active-filter'
                      }`}
                    >
                      <h4>
                        Mobile {planLabel(mobileProducts)}{' '}
                        <span>({mobileProducts.length})</span>
                      </h4>
                      <p>
                        ${minPrices.mobileProducts} {isMobile ? '+' : 'and up'}{' '}
                      </p>
                    </div>
                  </Col>
                )}
                {isBundleVisible && (
                  <Col span={6} onClick={() => activeTabHandler('bundle')}>
                    <div
                      className={`single-filter text-center ${
                        currentTab === 'bundle' && 'active-filter'
                      }`}
                    >
                      <h4>
                        Bundled {planLabel(bundles)}{' '}
                        <span>({bundleData?.length})</span>
                      </h4>
                      <p>
                        $
                        {minPrices.bundles &&
                          Number(minPrices.bundles)?.toFixed(2)}{' '}
                        {isMobile ? '+' : 'and up'}{' '}
                      </p>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        )}
        {OrOperator(buyflow === 'otherMobile' , buyflow === 'otherInt') && (
          <div className="info-tag">
            <i className="fa fa-info-circle" aria-hidden="true"></i>{' '}
            {buyflow === 'otherMobile' ? 'Internet' : 'Mobile'} plans are not
            available, check other plans available for this address.
          </div>
        )}
        <div className={` ${!showTab ? 'no-tabs' : ''}`}>
          <Row>
            <Col span={16} className={showTabClasses()}>
              {OrOperator(currentTab === 'all' , currentTab === 'internet') &&
                OrOperator(showInternet , buyflow === 'otherInt') && (
                <div>
                  <InternetPlans
                    serviceabilityCheck={serviceabilityCheck}
                    productSpd300={check300}
                    onResetAddress={handleRestAddress}
                    normalizedAddress={normalizedAddress}
                    productTfn={tfn}
                  />
                </div>
              )}
              {OrOperator(['all','mobile'].includes(currentTab) , buyflow === 'wireless') &&
               OrOperator(showMobile , buyflow === 'otherMobile') && (
                <div className="mobilephone-outer">
                  <MobilePhonePlans
                    serviceabilityCheck={serviceabilityCheck}
                    productTfn={tfn}
                  />
                </div>
              )}
              {isBundleVisible && (
                <>
                  {OrOperator(currentTab === 'all' , currentTab === 'bundle') &&
                    OrOperator(showBundle , buyflow === 'bundle') && (
                    <div className="bundle-outer">
                      <BundlePlans
                        serviceabilityCheck={serviceabilityCheck}
                        allProducts={allProducts}
                        bundlePrices={bundlePrices}
                        setBundlePrices={setBundlePrices}
                        productTfn={tfn}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
            <Col
              span={8}
              className={`p-left-1 ${showCallNow ? 'agent-hidden' : ''}`}
            >
              <CartInformation />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Product;
