import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import {
  getAggregatedAddress,
  getProductPrice,
  getPromoPrice,
  getValid$Cost,
  getWirelessServRef,
  vendorInformation,
} from '../../../common/common';
import { useDispatch } from 'react-redux';
import {
  getServicePropsByCode,
  saveInternetPlan,
  saveVasPlanInfo,
  saveWirelessPlanInfo,
  setMBoxReqObj,
  setSelectedBundle,
  setSelectedProduct,
} from '../../../store/action/OrderAction';
import {
  useBusinessAddr,
  useCallCenterFlag,
  useCartData,
  useMbox,
  useSessionData,
} from '../../../hooks/order';
import {
  EECproductAddToCart,
  EECproductRemoveFromCart,
} from '../../../common/GTMScripts';
import { v4 as uuid } from 'uuid';
import useLeadsSDK from '../../../hooks/useLeadsSDK';
import WhiModal from '../../common/WhiModal';
import PropTypes from 'prop-types';

import ReviewCarousel from './ReviewCarousel';
import { Disclaimer } from './Disclaimer';
import SpeedCard from './speedCard';
import { EarthlinkPerks } from './EarthlinkPerks';

const InternetPlans = (props) => {
  const leads = useLeadsSDK();
  const dispatch = useDispatch();
  const {
    serviceabilityCheck,
    productSpd300,
    productTfn,
    normalizedAddress,
    onResetAddress,
  } = props;

  const { plans, serviceTerm, fixedWirelessProducts } = serviceabilityCheck;
  const isWinterPromo = true;

  const [showWhiAddedModal, setShowWhiAddedModal] = useState(false);
  const [showFilteredPlans, setShowFilteredPlans] = useState(true);
  const {
    internetPlan = {},
    selectedBundleData,
    isBundleAdded,
    selectedProduct,
  } = useCartData();
  const { mboxRequired } = useMbox();
  const isInternetAdded = Object.keys(internetPlan).length > 0;
  const { showCallNow, showPromoStickers } = useCallCenterFlag();
  const { extendedInfo, customTFN, customWhiTFN } = useSessionData();
  const { isBusinessAddress } = useBusinessAddr();
  const handleAddIntToCart = (product) => {
    dispatch(getServicePropsByCode(product.servCode)).then((res) => {
      mboxRequired[product.servCode] =
        res.action?.payload?.data?.item?.isMboxReq;
      dispatch(setMBoxReqObj(mboxRequired));
    });
    dispatch(saveInternetPlan(product));
    EECproductAddToCart(product);
    leads?.updateOrder?.('add internet product to cart', {
      products: [
        {
          data: {
            category: 'internet',
            contractLength: product?.contractLength,
            downstreamSpd: product?.downstreamSpd,
            ebbInstallationCost: product?.ebbInstallationCost,
            ebbShippingCost: product?.ebbShippingCost,
            equipmentCost: product?.equipmentCost,
            upstreamSpd: product?.upstreamSpd,
          },
          id: String(product?.servRef),
          name: product?.serviceName,
          price: String(product?.price),
          quantity: 1,
        },
      ],
    });
  };

  const addIntToCart = (product) => {
    if (isInternetAdded) {
      if (isBundleAdded && selectedBundleData?.category !== 'mobile') {
        dispatch(
          saveVasPlanInfo({
            vasPlan: [],
            vasProductCart: [],
          }),
        );
        dispatch(
          saveWirelessPlanInfo({
            wirelessPlanInfo: [],
            wirelesServeRef: getWirelessServRef([]),
          }),
        );
        dispatch(setSelectedBundle(null));
      }

      delete mboxRequired[product.servCode];
      dispatch(setMBoxReqObj(mboxRequired));
      EECproductRemoveFromCart(product);
      dispatch(saveInternetPlan({}));

      /**
       * Remove internet product from cart
       */
      leads?.updateOrder?.('remove internet product from cart', {
        products: [
          {
            id: String(product?.servRef),
            name: product?.serviceName,
            quantity: 0,
          },
        ],
      });
    } else if (
      product.servCode?.toLowerCase() === 'elfw' &&
      !isBusinessAddress
    ) {
      setShowWhiAddedModal(true);
      dispatch(setSelectedProduct(product));
    } else {
      handleAddIntToCart(product);
    }
  };

  let sortedData = [];

  if (plans) {
    sortedData = plans.sort((a, b) => {
      return a.downstreamSpd - b.downstreamSpd;
    });
  }

  const showDiscount = (product) => {
    const { downstreamSpd = 0 } = product;
    const vendor = vendorInformation(product, extendedInfo);
    if (vendor.toLowerCase() === 'frontier') {
      if (downstreamSpd / 1000 === 200) {
        return 'noDiscount';
      } else if (downstreamSpd / 1000 > 200) {
        return 'moreDiscount';
      } else {
        return '';
      }
    } else if (
      vendor.toLowerCase() === 'centurylink' &&
      downstreamSpd / 1000 >= 100
    ) {
      return 'noDiscount';
    } else if (downstreamSpd / 1000 === 100) {
      return 'Discount';
    } else if (downstreamSpd / 1000 > 100) {
      return 'Discount';
    } else {
      return '';
    }
  };
  const customTfn = () => {
    const hasPlanUnder100mb = plans?.some(
      (product) => Number(product.downstreamSpd) < 100000,
    );
    const planGreaterThan100 = plans?.some(
      (product) => Number(product.downstreamSpd) > 100000,
    );

    if (customWhiTFN && fixedWirelessProducts?.length > 0) {
      return customWhiTFN;
    } else if (customTFN) {
      return customTFN;
    } else if (hasPlanUnder100mb) {
      return '833-905-7575';
    } else if (planGreaterThan100) {
      return '833-905-5435';
    } else {
      return productTfn;
    }
  };
  const filteredPlans = useMemo(() => {
    return plans?.filter(
      (item) =>
        item.downstreamSpd === '300000' ||
        item.downstreamSpd === '1000000' ||
        item.downstreamSpd === '5000000',
    );
  }, [plans]);

  useEffect(() => {
    setShowFilteredPlans(filteredPlans?.length > 0);
  }, [filteredPlans]);

  const toggleData = () => {
    setShowFilteredPlans((prevState) => !prevState);
  };

  const showMostPopular = (i, product) => {
    const { downstreamSpd = 0 } = product;
    const productSpd = downstreamSpd / 1000 === 300;
    const vendor = vendorInformation(product, extendedInfo);

    if (vendor.toLowerCase() === 'at&t' && productSpd) {
      return 'mostPop';
    }
    if (
      i.toString() ===
        `${
          showCallNow || showPromoStickers ? displayedPlans.length - 1 : '0'
        }` &&
      !productSpd300
    ) {
      return 'mostPop';
    }
    return '';
  };

  const allplansClass = () => {
    if (showPromoStickers) {
      return 'promo-sticker';
    } else if (plans.length) {
      return 'internet-promo';
    }
  };
  const convertSpeedUnit = (speed) => {
    if (speed?.toLowerCase().includes('mb')) {
      return speed.replace(/mb/i, ' Mbps');
    } else if (speed?.toLowerCase().includes('gb')) {
      return speed.replace(/gb/i, ' Gigs');
    }
    return speed;
  };

  useEffect(() => {
    if (Object.keys(internetPlan).length) {
      const isMatch = filteredPlans.some(
        (item) => item.downstreamSpd === internetPlan.downstreamSpd,
      );

      setShowFilteredPlans(isMatch);
    }
  }, [internetPlan]);
  const pathname =
    '/' +
    window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const displayedPlans = showFilteredPlans ? filteredPlans : sortedData;
  let condition = showCallNow || showPromoStickers;

  return (
    <>
      <div className="internet-gift-card-section">
        {isWinterPromo ? (
          ''
        ) : fixedWirelessProducts.length === 0 && pathname === '/products' ? (
          <div>
            Use code <span>FIBER24 </span>to receive a $200 gift card**
          </div>
        ) : null}
      </div>

      {filteredPlans?.some((plan) => plan.downstreamSpd === '300000') && (
        <div className="internet-result-price-heading">
          <p>
            {(() => {
              const plan300 = filteredPlans?.find(
                (plan) => plan.downstreamSpd === '300000',
              );
              const price = isWinterPromo
                ? '39.95'
                : getPromoPrice(plan300, serviceTerm) ||
                  getProductPrice(plan300, serviceTerm);
              return (
                <>Upgrade to Ultra Fast Fiber for just {price} / month&#42;</>
              );
            })()}
          </p>
        </div>
      )}

      <div className="internet-result-heading internetOuterClass">
        <h1>
          {fixedWirelessProducts?.length > 0 && plans.length > 0
            ? 'Congratulations – you\'re in our network!'
            : fixedWirelessProducts?.length > 0 && plans.length === 0
              ? 'Checkout'
              : 'Congratulations – you\'re in our network!'}
        </h1>
        {filteredPlans?.some((plan) => plan.downstreamSpd === '300000') && (
          <p className="address-paragraph">
            {(() => {
              const plan300 = filteredPlans?.find(
                (plan) => plan.downstreamSpd === '300000',
              );
              const price = isWinterPromo
                ? '39.95'
                : getPromoPrice(plan300, serviceTerm) ||
                  getProductPrice(plan300, serviceTerm);
              return isWinterPromo ? (
                <>
                  You’re one step closer to internet at the speed of light. Call
                  now and lock in this limited time offer: fast fiber internet
                  for only {price} / mo&#42;.
                </>
              ) : (
                <>
                  Upgrade to fiber internet starting at just $39.95 /
                  month&#42;. But don’t wait, our offer is ending soon!
                </>
              );
            })()}
          </p>
        )}
        <p className="address-paragraph">
          Let&apos;s get you connected at{' '}
          <b>{getAggregatedAddress(normalizedAddress)}</b>
          &nbsp;<button onClick={onResetAddress}>Change Address</button>
        </p>
        {fixedWirelessProducts?.length === 0 ? (
          <div className="toggle-button-container">
            <button onClick={toggleData}>
              Show all speeds &nbsp;
              {showFilteredPlans ? (
                <i className="fa-solid fa-angle-up"></i>
              ) : (
                <i className="fa-solid fa-angle-down"></i>
              )}
            </button>
          </div>
        ) : null}

        <div
          className={`all-single-plans internetOuterClass ${allplansClass()}`}
        >
          {/* All plans data */}
          <Row>
            {plans?.length > 0 && fixedWirelessProducts?.length > 0
              ? plans?.map((product, i) => {
                let serviceDesc = '';
                serviceDesc = product?.serviceDesc
                  ?.split('.')
                  .filter((item) => item !== '')
                  .map((listItem) => {
                    return (
                      <li key={uuid()}>
                        <p>{listItem && listItem + '.'}</p>
                      </li>
                    );
                  });

                return (
                  <Col
                    md={24}
                    key={uuid()}
                    lg={showCallNow ? 8 : 12}
                    className={`plan-single ${showMostPopular(i, product)} ${
                      condition ? showDiscount(product) : ''
                    }`}
                  >
                    <div className="plan-single-inner">
                      <div className="plans-header">
                        <h4>
                          {product?.downstreamSpeed &&
                              convertSpeedUnit(product?.downstreamSpeed)}
                        </h4>
                        <SpeedCard
                          speed={
                            product?.downstreamSpd &&
                              product?.downstreamSpd / 1000
                          }
                        />
                      </div>
                      <ul>{serviceDesc}</ul>

                      {!showCallNow ? (
                        <div
                          className={`down-cart d-flex al-center j-between ${
                            showPromoStickers ? '' : 'show-promo'
                          }`}
                        >
                          <button
                            className={`blue-button ${
                              isInternetAdded ? 'grey-button' : ''
                            }`}
                            disabled={
                              isInternetAdded &&
                                internetPlan.servRef !== product.servRef
                            }
                            onClick={() => {
                              addIntToCart(product);
                            }}
                          >
                            {isInternetAdded &&
                              internetPlan.servRef === product.servRef
                              ? 'Remove'
                              : 'Add'}{' '}
                            <i
                              className={`fa-solid ${
                                isInternetAdded &&
                                  internetPlan.servRef === product.servRef
                                  ? 'fa-times'
                                  : 'fa-solid fa-cart-shopping'
                              }`}
                            ></i>
                          </button>

                          <div className="main-below-total">
                            {getPromoPrice(product, serviceTerm) && (
                              <span className="discount">
                                {getValid$Cost(product.price)}
                              </span>
                            )}
                            <span className="total-single">
                              {getPromoPrice(product, serviceTerm)
                                ? getPromoPrice(product, serviceTerm)
                                : getProductPrice(product, serviceTerm)}
                                / mo
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="agent-call-button">
                          <a
                            href={`tel:${customTfn()}`}
                            className="blue-button elnk-tfn-link"
                          >
                            <i className="fa-solid fa-phone-volume"></i>
                            <span className="phone-span elnk-tfn-tag">
                              {customTfn()}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                );
              })
              : // Rendering filteredPlans
              displayedPlans?.map((product, i) => {
                let serviceDesc = '';
                serviceDesc = product?.serviceDesc
                  ?.split('.')
                  .filter((item) => item !== '')
                  .map((listItem) => {
                    return (
                      <li key={uuid()}>
                        <p>{listItem && listItem + '.'}</p>
                      </li>
                    );
                  });

                return (
                  <Col
                    md={24}
                    key={uuid()}
                    lg={showCallNow ? 8 : 12}
                    className={`plan-single ${showMostPopular(i, product)} ${
                      condition ? showDiscount(product) : ''
                    }`}
                  >
                    <div className="plan-single-inner">
                      <div className="plans-header">
                        <h4>
                          {product?.downstreamSpeed &&
                              convertSpeedUnit(product?.downstreamSpeed)}
                        </h4>
                        <SpeedCard
                          speed={
                            product.downstreamSpd &&
                              product.downstreamSpd / 1000
                          }
                        />
                      </div>
                      <ul>{serviceDesc}</ul>

                      {!showCallNow ? (
                        <div
                          className={`down-cart d-flex al-center j-between ${
                            showPromoStickers ? '' : 'show-promo'
                          }`}
                        >
                          <button
                            className={`blue-button ${
                              isInternetAdded ? 'grey-button' : ''
                            }`}
                            disabled={
                              isInternetAdded &&
                                internetPlan.servRef !== product.servRef
                            }
                            onClick={() => {
                              addIntToCart(product);
                            }}
                          >
                            {isInternetAdded &&
                              internetPlan.servRef === product.servRef
                              ? 'Remove'
                              : 'Add'}{' '}
                            <i
                              className={`fa-solid ${
                                isInternetAdded &&
                                  internetPlan.servRef === product.servRef
                                  ? 'fa-times'
                                  : 'fa-solid fa-cart-shopping'
                              }`}
                            ></i>
                          </button>

                          <div className="main-below-total">
                            {getPromoPrice(product, serviceTerm) && (
                              <span className="discount">
                                {getValid$Cost(product.price)}
                              </span>
                            )}
                            <span className="total-single">
                              {getPromoPrice(product, serviceTerm)
                                ? getPromoPrice(product, serviceTerm)
                                : getProductPrice(product, serviceTerm)}
                                / mo
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="agent-call-button">
                          <a
                            href={`tel:${customTfn()}`}
                            className="blue-button elnk-tfn-link"
                          >
                            <i className="fa-solid fa-phone-volume"></i>
                            <span className="phone-span elnk-tfn-tag">
                              {customTfn()}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                );
              })}

            {/* Rendering fixedWirelessProducts */}
            {fixedWirelessProducts &&
              fixedWirelessProducts.length > 0 &&
              fixedWirelessProducts.map((data) => {
                let serviceDesc = '';
                serviceDesc = data?.serviceDescription
                  ?.split('.')
                  .filter((item) => item !== '')
                  .map((listItem) => {
                    return (
                      <li key={uuid()}>
                        <p>{listItem && listItem + '.'}</p>
                      </li>
                    );
                  });

                return (
                  <Col
                    md={24}
                    lg={showCallNow ? 8 : 12}
                    key={uuid()}
                    className="plan-single"
                  >
                    <div className={'plan-single-inner wireless-prod'}>
                      <div className="plans-header">
                        <h4>
                          {data.serviceName
                            .replace('plan', '')
                            .replace('Plan', '')}{' '}
                          Data
                        </h4>
                      </div>
                      <ul>{serviceDesc}</ul>

                      {!showCallNow ? (
                        <div className="down-cart d-flex al-center j-between">
                          <button
                            className={`blue-button ${
                              isInternetAdded && 'grey-button'
                            }`}
                            disabled={
                              isInternetAdded &&
                              internetPlan.servRef !== data.servRef
                            }
                            onClick={() => {
                              addIntToCart(data);
                              dispatch(setSelectedProduct(data));
                            }}
                          >
                            {isInternetAdded &&
                            internetPlan.servRef === data.servRef
                              ? 'Remove'
                              : 'Add'}{' '}
                            <i
                              className={`fa-solid ${
                                isInternetAdded &&
                                internetPlan.servRef === data.servRef
                                  ? 'fa-times'
                                  : 'fa-solid fa-cart-shopping'
                              }`}
                            ></i>
                          </button>

                          <div className="main-below-total">
                            {getPromoPrice(data) && (
                              <span className="discount">
                                {getValid$Cost(data.price)}
                              </span>
                            )}
                            <span className="total-single">
                              {getPromoPrice(data)
                                ? getPromoPrice(data)
                                : getValid$Cost(data.price)}{' '}
                              / mo
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="agent-call-button">
                          <a
                            href={`tel:${customTfn()}`}
                            className="blue-button elnk-tfn-link"
                          >
                            <i className="fa-solid fa-mobile-screen-button"></i>{' '}
                            <span className="phone-span elnk-tfn-tag">
                              {customTfn()}
                            </span>
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>

      {fixedWirelessProducts?.length === 0 ? (
        <>
          <EarthlinkPerks showCallNow={showCallNow} />
          <ReviewCarousel showCallNow={showCallNow} />
          <Disclaimer />
        </>
      ) : null}

      <WhiModal
        showWhiAddedModal={showWhiAddedModal}
        setShowWhiAddedModal={setShowWhiAddedModal}
        selectedProduct={selectedProduct}
        handleAddIntToCart={handleAddIntToCart}
      />
    </>
  );
};

InternetPlans.propTypes = {
  serviceabilityCheck: PropTypes.object.isRequired,
  productSpd300: PropTypes.bool.isRequired,
  productTfn: PropTypes.string.isRequired,
  normalizedAddress: PropTypes.object.isRequired,
  onResetAddress: PropTypes.func.isRequired,
};

export default InternetPlans;
