import {
  ACCOUNT_CHECK,
  CHECK_SERVICEABILITY,
  VERIFY_ADDRESS_SERVICEABILITY,
  INITIAL_ORDER_CREATE,
  RESET_STATE,
  RESET_SUBMIT_RESPONSE,
  SAVE_BUSINESS_INFO,
  SAVE_CHALLENGE_QUESTION_INFO,
  SAVE_PASSWORD,
  SAVE_PAYMENT_INFO,
  SAVE_PLAN_INFO,
  SAVE_VAS_PLAN_INFO,
  SERVICE_CHECK,
  SERVICE_CHECK_FULFILLED,
  SESSION_CALL,
  SUBMIT_ORDER,
  UPDATE_CARD_ERROR,
  UPDATE_CUST_TC,
  UPDATE_ERROR_MSG,
  UPDATE_IS_BUSINESS_ADDRESS,
  UPDATE_ORDER,
  UPDATE_TOKENS,
  SET_TFN,
  SET_EXPRESS_LOADING,
  EXPRESS_VAS,
  SAVE_BUYFLOW,
  SAVE_WIRELESS_PLAN_INFO,
  CHECK_MOBILE_PRODUCTS,
  CHECK_DEVICE_COMPATIBILITY,
  SAVE_CHECKOUT_INFO,
  SCHEDULE_APPT_CHECK,
  SAVE_SCHEDULE_DATE_TIME,
  GET_SERVICE_PROPS_BY_CODE,
  SET_ELNK_MAILBOX_REQ,
  SET_MAILBOX_REQ,
  GET_ACC_DOMAINS,
  VALIDATE_CARD,
  SET_MOBILE_CONTAINER,
  SAVE_STEP_ONE,
  MOBILE_PRODUCTS,
  SAVE_TCPA_DISCLAIMER,
  SAVE_SPANISH_LANGUAGE_PREFERENCE,
  GET_BUNDLES_DETAILS,
  SET_BUNDLE_PRODUCT,
  SAVE_MOBILEPROVIDER,
  SAVE_PHONENUMBER,
  SET_CUSTOM_TFN,
  SET_CUSTOM_WHI_TFN,
  SET_REFERRER,
  SET_RTS_VISIBLE,
  SET_RTS_ATTEMPT_COUNT,
  SET_ACTIVE_PRODUCT_TAB,
  SET_SELECTED_PRODUCT,
  SEND_BROADBAND
} from './Types';
import { api } from '../../util/axios'
import { getConfigs } from '../../config';

const config = getConfigs();

export const sessionCall = (landingPageUid) => {
  const data = {
    landingPageUid,
  }
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/sessions/landing-pages`,
    data,
  })
  return {
    type: SESSION_CALL,
    payload: request
  }
}

export const getExpressVasProducts = (promoCode) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.EXPRESS_VAS}/vas-products/${promoCode}/${config.BUSINESS_PARTNER}`,
  });
  return {
    type: EXPRESS_VAS,
    payload: request
  }
}

export const initialOrderCreate = orderDetails => {
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/initial-order-create`,
    data: orderDetails,
  })
  return {
    type: INITIAL_ORDER_CREATE,
    payload: request
  }
}

export const updateOrder = (details, orderId) => {
  const request = api.axios({
    method: 'PUT',
    url: `${config.API_ENDPOINT}/order/${orderId}`,
    data: details
  })
  return {
    type: UPDATE_ORDER,
    payload: request
  }
}

export const validateCreditCard = (validateCardModel) => {
  const response = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/cc-validate`,
    data: validateCardModel
  })
  return {
    type: VALIDATE_CARD,
    payload: response
  }
}

export const saveInternetPlan = planDetails => {
  return {
    type: SAVE_PLAN_INFO,
    payload: planDetails
  }
}

export const getBundleDetails = servCodes => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/bundles/?bundlecodes=${servCodes}`,
  })
  return {
    type: GET_BUNDLES_DETAILS,
    payload: request
  }
}

export const saveVasPlanInfo = planDetails => {
  return {
    type: SAVE_VAS_PLAN_INFO,
    payload: planDetails
  }
}

export const resetState = () => {
  return {
    type: RESET_STATE
  }
}

export const resetSubmitResponse = () => {
  return {
    type: RESET_SUBMIT_RESPONSE,
  }
}


export const checkServiceability = (checkServiceabilityModel) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.CHECK_SERVICEABILITY}/prequal`,
    data: checkServiceabilityModel
  });
  return {
    type: CHECK_SERVICEABILITY,
    payload: request
  }
}

export const validateAddressServiceability = (checkServiceabilityAddress) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.CHECK_SERVICEABILITY}/validate-address`,
    data: checkServiceabilityAddress
  });
  return {
    type: VERIFY_ADDRESS_SERVICEABILITY,
    payload: request
  }
}


export const updateTokens = (tokens) => {
  return {
    type: UPDATE_TOKENS,
    tokens
  }
}

export const serviceCheck = (serviceCheckModel) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/service-check`,
    data: serviceCheckModel
  });
  return {
    type: SERVICE_CHECK,
    payload: request
  }
}

export const saveServiceCheckId = id => {
  return {
    type: SERVICE_CHECK_FULFILLED,
    payload: id
  }
}

export const checkMobileProducts = () => {
  const request = api.axios({
    method: 'GET',
    url: `${config.CHECK_SERVICEABILITY}/mobile-products/07712/elnkac`,
  });
  return {
    type: CHECK_MOBILE_PRODUCTS,
    payload: request
  }
}

export const checkDeviceCompatibility = (deviceCheckModel) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.CHECK_SERVICEABILITY}/device-compatibility`,
    data: deviceCheckModel
  });
  return {
    type: CHECK_DEVICE_COMPATIBILITY,
    payload: request
  }
}

export const accountCheck = (accountCheckModel) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/account-check`,
    timeout: 1000 * 5,
    data: accountCheckModel,
  });
  return {
    type: ACCOUNT_CHECK,
    payload: request
  }
}

export const CheckScheduleAppt = (scheduleAppt) => {
  const request = api.axios({
    method: 'POST',
    url: `${config.CHECK_SERVICEABILITY}/install-status`,
    data: scheduleAppt
  });
  return {
    type: SCHEDULE_APPT_CHECK,
    payload: request
  }
}

export const saveScheduleDateTime = (dateTime) => {
  return {
    type: SAVE_SCHEDULE_DATE_TIME,
    payload: dateTime
  }
}

export const updateIsBusinessAddress = isBusinessAddress => {
  return {
    type: UPDATE_IS_BUSINESS_ADDRESS,
    payload: isBusinessAddress
  }
}

export const saveBusinessInfo = businessInfoModel => {
  return {
    type: SAVE_BUSINESS_INFO,
    payload: businessInfoModel
  }
}

export const saveChgQuestionInfo = chgQuestionModel => {
  return {
    type: SAVE_CHALLENGE_QUESTION_INFO,
    payload: chgQuestionModel
  }
}

export const savePaymentInfo = paymentDetails => {
  return {
    type: SAVE_PAYMENT_INFO,
    payload: paymentDetails
  }
}

export const saveBuyflow = buyflow => {
  return {
    type: SAVE_BUYFLOW,
    payload: buyflow
  }
}

export const saveWirelessPlanInfo = planDetails => {
  return {
    type: SAVE_WIRELESS_PLAN_INFO,
    payload: planDetails
  }
}

export const saveCheckoutInfo = Details => {
  return {
    type: SAVE_CHECKOUT_INFO,
    payload: Details
  }
}

export const updateCustTC = custDetail => {
  return {
    type: UPDATE_CUST_TC,
    payload: custDetail
  }
}

export const updateErrorMsg = message => {
  return {
    type: UPDATE_ERROR_MSG,
    payload: message
  }
}

export const updateCardErrorModal = message => {
  return {
    type: UPDATE_CARD_ERROR,
    payload: message
  }
}

export const setExpressLoading = value => {
  return {
    type: SET_EXPRESS_LOADING,
    payload: value
  }
}

export const savePassword = password => {
  return {
    type: SAVE_PASSWORD,
    payload: password
  }
}
export const submitOrder = (submitOrderModel) => {
  const response = api.axios({
    method: 'POST',
    url: submitOrderModel.isExpressContainer ? `${config.API_ENDPOINT}/order-submission-vas` : `${config.API_ENDPOINT}/order-submission`,
    data: submitOrderModel
  })
  return {
    type: SUBMIT_ORDER,
    payload: response
  }
}

export const setTFN = nbc => {
  return {
    type: SET_TFN,
    payload: nbc
  }
}

export const getServicePropsByCode = (servCode) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/services/${servCode}`,
  });
  return {
    type: GET_SERVICE_PROPS_BY_CODE,
    payload: request,
  }
}

export const setElnkMailboxReq = (value) => {
  return {
    type: SET_ELNK_MAILBOX_REQ,
    payload: value,
  }
}

export const setMBoxReqObj = (value) => {
  return {
    type: SET_MAILBOX_REQ,
    payload: value,
  }
}

export const getAccountDomains = () => {
  const request = api.axios({
    method: 'GET',
    url: `${config.API_ENDPOINT}/accounts/domains`,
  });
  return {
    type: GET_ACC_DOMAINS,
    payload: request,
  }
}

export const setMobileOnlyContainer = (value) => {
  return {
    type: SET_MOBILE_CONTAINER,
    payload: value,
  }
}

export const saveFirstStep = value => {
  return {
    type: SAVE_STEP_ONE,
    payload: value,
  }
}

export const getMobileProducts = (promoCode) => {
  const request = api.axios({
    method: 'GET',
    url: `${config.CHECK_SERVICEABILITY}/mobile-products/${promoCode}/${config.BUSINESS_PARTNER}`,
  });
  return {
    type: MOBILE_PRODUCTS,
    payload: request
  }
}

export const saveTCPADisclaimer = value => {
  return {
    type: SAVE_TCPA_DISCLAIMER,
    payload: value
  }
}

export const saveSpanishLanguagePreference = value => {
  return {
    type: SAVE_SPANISH_LANGUAGE_PREFERENCE,
    payload: value
  }
}

export const setSelectedBundle = (data) => ({
  type: SET_BUNDLE_PRODUCT,
  payload: data,
});
export const savePhoneNumber = (phone) => {
  const data = {
    phoneNumber: phone,
    appClient: 'www',
    appClientData: {
      app: 'www',
      name: 'checkout',
    }
  }
  const request = api.axios({
    method: 'POST',
    url: `${config.API_ENDPOINT}/getall`,
    data,
  });
  return {
    type: SAVE_PHONENUMBER,
    payload: request
  }
}

export const saveMobileProvider = (provider) =>{
  return{
    type: SAVE_MOBILEPROVIDER,
    payload: provider
  }
}

export const setCustomTFN = tfn => {
  return {
    type: SET_CUSTOM_TFN,
    payload: tfn
  }
}

export const setCustomWhiTFN = tfn => {
  return {
    type: SET_CUSTOM_WHI_TFN,
    payload: tfn
  }
}

export const setReferrer = tfn => {
  return {
    type: SET_REFERRER,
    payload: tfn
  }
}

export const setRtsVisible = (data) => ({
  type: SET_RTS_VISIBLE,
  payload: data,
})

export const setRtsAttemptCount = (data) => ({
  type: SET_RTS_ATTEMPT_COUNT,
  payload: data,
})

export const setActiveProductTab = (data) => ({
  type: SET_ACTIVE_PRODUCT_TAB,
  payload: data,
})

export const setSelectedProduct = (data) => (
  {
    type: SET_SELECTED_PRODUCT,
    payload: data,
  })

export const sendBroadbandEmail = (details,promoCode,partnerId,serviceRef) => {
  const request = api.axios({
    method: 'PUT',
    url: `${config.API_ENDPOINT}/email/broadband/labels/${promoCode}/${partnerId}/${serviceRef}`,
    data: details
  });
  return {
    type: SEND_BROADBAND,
    payload: request,
  };
};
