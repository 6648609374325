import React from 'react';
import { Row, Col } from 'antd';

const Protect = () => {
  return (
    <div className="protect-section border-same m-top-3 same-protect">
      <Row>
        <Col span={16}>
          <div className="protect-title">
            <h2 className="vas-main-heading">EarthLink Protect</h2>
            <h4>Device Security & Online Privacy</h4>
            <p>EarthLink Protect, powered by Norton, helps detect threats to your devices, online privacy and personal information on the dark web as you surf, bank, socialize and shop online.</p>
          </div>
        </Col>
        <Col span={8} className="text-center responsive-m-t-2">
          <div className="protect-contact">
            <a href="tel:833-957-0229" className="blue-button"><i className="fa fa-phone"></i>Call 833-957-0229</a>
            <h4>
              Starting at $7.95/mo.
              <br></br>
              Free 30-day trial*
            </h4>
          </div>
        </Col>

      </Row>
      <div className="what-you-get">
        <h3>What you Get</h3>
      </div>

      <Row>
        <Col span={12}>

          <ul className="blue-circle-lists">
            <li><i className="fas fa-check-circle"></i><strong>Real-time protection</strong> against ransomware, viruses, spyware, malware and other online threats</li>
            <li><i className="fas fa-check-circle"></i><strong>Defends your connection</strong> by securing your device when you go online – at home and on-the-go</li>
            <li><i className="fas fa-check-circle"></i><strong>Monitor the dark web</strong> and receive notifications when your personal information is found</li>
          </ul>

        </Col>
        <Col span={12}>

          <ul className="blue-circle-lists">
            <li><i className="fas fa-check-circle"></i><strong>Additional features </strong> include Parental Controls‡ to help your kids safely explore the Internet, PC SafeCam to safeguard the other window into your home, and Password Manager to centrally manage your online credentials</li>
            <li><i className="fas fa-check-circle"></i><strong>Free 30-day trial*</strong>
              {/* – <a href="#"> More Information </a> */}
            </li>
          </ul>

        </Col>
      </Row >

    </div >
  )
}
export default Protect
