import React from 'react';
import { Row, Col } from 'antd'

const ProtectPlus = () => {
  return (
    <div className="protect-section border-same m-top-3 same-protect">
      <Row>
        <Col span={16}>
          <div className="protect-title">
            <h2>EarthLink Protect+</h2>
            <h4>The all-in-one cybersecurity solution for your life in the connected world</h4>
            <p>EarthLink Protect+, powered by Norton, is designed to bring you comprehensive all-in-one protection for your connected devices, online privacy and identity</p>
          </div>
        </Col>
        <Col span={8}>
          <div className="protect-contact text-center responsive-m-t-2">
            <a href="tel:833-957-0229" className="blue-button"><i className="fa fa-phone"></i>Call 833-957-0229</a>
            <h4>
              Starting at $9.95/mo.
              <br></br>
              Free 30-day trial*
            </h4>
          </div>
        </Col>
      </Row>

      <div className="what-you-get">
        <h3>What you Get</h3>
      </div>

      <Row>
        <Col span={12}>
          <ul className="blue-circle-lists">
            <li><i className="fas fa-check-circle"></i><strong>Real-Time Threat Protection </strong>Advanced Norton Device Security to help protect against ransomware, viruses, spyware, malware and other online threats</li>
            <li><i className="fas fa-check-circle"></i><strong>Secure VPN </strong>Defends your online privacy by securing your online connections from cybercriminals, both at home and on-the-go</li>
            <li><i className="fas fa-check-circle"></i><strong>Dark Web Monitoring Powered by LifeLock™</strong> LifeLock patrols the dark web and notifies you if your personal information is found on the dark web.</li>
            <li><i className="fas fa-check-circle"></i><strong>LifeLock Identity Alert™ System† </strong> Alerts you to activity like use of your Social Security number, name, address, or date of birth in applications for credit and services.</li>
          </ul>
        </Col>
        <Col span={12}>
          <ul className="blue-circle-lists">
            <li><i className="fas fa-check-circle"></i><strong>Million Dollar Protection™ Package</strong> Reimbursement of funds stolen and coverage for personal expenses up to the limit of your plan††† to help you resolve issues and restore your identity</li>
            <li><i className="fas fa-check-circle"></i><strong>Data Breach Notifications</strong> Updates on large-scale breaches, so you can help protect your personal information</li>
            <li><i className="fas fa-check-circle"></i><strong>Free 30-day trial*</strong>
              {/* – <a href="#">More Information</a> */}
            </li>
          </ul>
        </Col>
      </Row>

    </div>
  )
}
export default ProtectPlus
