import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialOrderCreate, sessionCall, setReferrer } from '../../store/action/OrderAction';
import ServiceabilityCheck from './ServiceabilityCheck';
import VasOnly from './VasOnly';
import { BackdropLoader } from '../common/Loader';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import MobileOnly from './MobileOnly';
import { OrderStatus } from '../../util/util';
import { useSessionData } from '../../hooks/order';
import { getConfigs } from '../../config';
import { isUndefinedNullOrEmpty, setSessionOrderId } from '../../common/common';
import useLeadsSDK from '../../hooks/useLeadsSDK';
const config = getConfigs();

const HomePage = (props) => {
  const leads = useLeadsSDK();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const { sessionResponse } = useSessionData();
  const { servRef = '', mobileServRef = '', referrer = '' } = queryString.parse(location.search);
  const gid = (sessionResponse && sessionResponse.uid) || queryString.parse(location.search).gid;

  useEffect(() => {
    if (!isUndefinedNullOrEmpty(gid) && !sessionResponse.uid) {
      dispatch(sessionCall(gid)).then((response) => {
        const { agentId, partnerId } = response.value.data
        dispatch(initialOrderCreate({
          orderStatus: OrderStatus.newSession,
          affiliateUserId: agentId,
          partnerName: partnerId,
        })).then((res) => {
          setSessionOrderId(res.value.data.id);
          setLoader(false)
          if(referrer){
            dispatch(setReferrer(referrer))
          }

          leads?.updateOrder?.('Initialize Order', {
            partner: {
              affiliate: {
                key: response?.value?.data?.affiliateKey,
                name: response?.value?.data?.affiliateName,
              },
              businessPromo: response?.value?.data?.businessPromoCode,
              id: response?.value?.data?.partnerId,
              promo: response?.value?.data?.promoCode,
            },
            session: {
              internalOrderId: res?.value?.data?.id,
              isBusiness: response?.value?.data?.businessFlag,
              isMaintenance: response?.value?.data?.maintenanceFlag,
              isVas: response?.value?.data?.vasFlag,
            },
          });
        }).catch(() => { window.location.href = config.BASE_URL })
      }).catch(() => { window.location.href = config.BASE_URL })
    } else {
      setLoader(false);
    }
  }, [dispatch, gid])

  if (loader) {
    return <BackdropLoader />
  }
  if (servRef) {
    return <VasOnly {...props} />;
  }
  if (mobileServRef) {
    return <MobileOnly  {...props} />
  }
  return <ServiceabilityCheck {...props} />;
}

export default React.memo(HomePage);
