import React, { useState , useCallback } from 'react';
import { Row, Col, Select, Input, Button, Modal } from 'antd';
import { EnvironmentOutlined, EditOutlined, CloseOutlined , CloseCircleFilled} from '@ant-design/icons';
import UnitType from '../../helpers/UnitType';
import { useLoading } from '../../hooks/order';
import { getSegregatedAddress, getAggregatedAddress } from '../../common/common';

// eslint-disable-next-line no-unused-vars
const VerifyAddressModal = (props) => {
  const [showSecondAddressLine, setShowSecondAddressLine] = useState(false)
  const [unitAddress, setUnitAddress] = useState({
    unitType: '',
    unitNumber: '',
    secondUnitType: '',
    secondUnitNumber: '',
  });
  const { unitType, unitNumber, secondUnitType, secondUnitNumber } = unitAddress;
  const address2 = `${unitType} ${unitNumber} ${secondUnitType} ${secondUnitNumber}`;
  const { address1, city, state, zip } = getSegregatedAddress(props?.searchAddress);
  const newManualAddress = {
    address1,
    address2: address2?.trimEnd(),
    city,
    zip,
    state,
  };
  const manualAddressValue =(newManualAddress?.address2?.length &&
     getAggregatedAddress(newManualAddress)) || '';

  const { loading } = useLoading();
  const handleThirdAddress = () => {
    setShowSecondAddressLine(!showSecondAddressLine);
    setUnitAddress({...unitAddress,secondUnitType: '',secondUnitNumber: '' })
  }

  const handleInputChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      setUnitAddress({ ...unitAddress, [name]: value?.trimStart() });
    },
    [unitAddress],
  );

  const handleSubmit = () => {
    // eslint-disable-next-line no-unused-expressions
    props?.handleSubmit(manualAddressValue, false);
  }

  const disabledSubmit = useCallback(() => {
    if (unitNumber.length <= 0 || unitType.length <= 0) {
      return true;
    }
    if (showSecondAddressLine) {
      if (secondUnitNumber.length <= 0 || secondUnitType.length <= 0) {
        return true;
      }
    }
    return false;
  }, [showSecondAddressLine, unitAddress]);

  return (
    <>
      <Modal
        title="Confirm Address Details and Type"
        visible={props.isModalOpen}
        footer={null}
        closable={false}
        onCancel={props.handleClose }
        onClose={props.handleClose}
        width={620}
        className="modalRoot"
      >
        <CloseOutlined className="close-icon"  onClick={props.handleClose} />
        <div className="address-header">
          <EnvironmentOutlined className="location-icon" />
          <p className="address-text">{props?.searchAddress}</p>
          <EditOutlined className="edit-address-icon" alt="Edit" onClick={props.handleClose}/>
        </div>
        <h4 style={{marginTop: '18px'}}>
          <b>This address has multiple units</b>
        </h4>
        <div className="row-conatiner">
          <Row gutter={[10, 10]}>
            <Col span={8} id="select-unit">
              <label className="address-label">Select Unit Type</label>
              <br />
              <Select
                allowClear
                showSearch
                placeholder="APT, UNIT, LOT, SPC"
                value={unitType ? unitType : 'APT, UNIT, LOT, SPC'}
                onSelect={(value) => {
                  setUnitAddress({ ...unitAddress, unitType: value });
                }}
              >
                {UnitType?.map(({ code, name }) => (
                  <Select.Option key={code} value={code}>
                    {code} - {name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={8} className="select-unit-input">
              <label className="address-label">Enter Unit Number</label>
              <br />
              <Input
                placeholder="12, 4G, 210"
                name="unitNumber"
                maxLength={6}
                onChange={handleInputChange}
                value={unitNumber}
              />
            </Col>
            {showSecondAddressLine ? (
              <><Col span={8} id="select-unit">
                <label className="address-label">Select Second Unit Type</label>
                <br />
                <Select
                  allowClear
                  showSearch
                  placeholder="APT, UNIT, LOT, SPC"
                  value={secondUnitType ? secondUnitType : 'APT, UNIT, LOT, SPC'}
                  onSelect={(value) => {
                    setUnitAddress({ ...unitAddress, secondUnitType: value });
                  }}
                >
                  {UnitType?.map(({ code, name }) => (
                    <Select.Option key={code} value={code}>
                      {code} - {name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={8} className="select-unit-input">
                <label className="address-label">Enter Unit Number</label>
                <br />
                <Input
                  placeholder="12, 4G, 210"
                  name="secondUnitNumber"
                  maxLength={6}
                  onChange={handleInputChange}
                  value={secondUnitNumber}
                />
              </Col>
              <Col style={{ marginTop:'27px'}}>
                <a onClick={handleThirdAddress}>
                  <CloseCircleFilled className="remove-second-unit"/>
                </a>
              </Col>
              </>) :
              <Col style={{ marginRight: '100px', padding : '0 5px'}}>
                <a onClick={handleThirdAddress} style={{color: '#007cba', fontWeight: 'bold'}}>
                  Add another
                </a>
              </Col>}
          </Row>
        </div>
        <div className="modal-button-container">
          <Button
            type="cancel"
            className="modal-cancel-button"
            disabled={loading}
            onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="modal-submit-button"
            loading={loading}
            disabled={disabledSubmit()}
            onClick={() => handleSubmit()}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default VerifyAddressModal;
