import React from 'react';
import { useCartData, useVasData } from '../../../hooks/order';
import { vasProductDescription } from '../../../util/util';

const CheckoutHeadText = () => {
  const { isVasOnlyFlow } = useVasData();
  const { vasPlan = [] } = useCartData();
  const checkPage = window.location.href.includes('review-order');

  const getVasDesc = () => {
    if (['126', '127'].includes(vasPlan?.[0]?.servRef)) {
      return vasProductDescription.Protect
    } else if (['128', '129', '130'].includes(vasPlan?.[0]?.servRef)) {
      return vasProductDescription.ProtectPlus
    } else if (vasPlan?.[0]?.servRef === '410') {
      return vasProductDescription.Guardian
    } else if (['131', '132', '133'].includes(vasPlan?.[0]?.servRef)) {
      return vasProductDescription.Backup
    } else if (['420', '421', '422'].includes(vasPlan?.[0]?.servRef)) {
      return vasProductDescription.EasyTech
    } else {
      return ''
    }
  }

  const resultHeading = () => {
    if (isVasOnlyFlow && vasPlan?.length > 0) {
      return vasPlan[0]?.serviceName
    }else if (checkPage) {
      return ''
    }
    return 'The results are in!'
  }

  return (
    <>
      <div className="result-heading">
        <h1>{resultHeading()}</h1>
        {isVasOnlyFlow &&
          <p>
            {
              getVasDesc()
            }
          </p>
        }
      </div>
    </>
  )
}
export default CheckoutHeadText;
