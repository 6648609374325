export const ValidationMessages = {
  required: 'This field is required',
  mobRequired: 'Field Required',
  passwordnotMatching: 'Passwords do not match',
  passwordInvalid: 'Incorrect Password format. Must be 8-32 characters and contain 3 of the following: uppercase letter, lowercase letter, a number or a special character',
  validCharsOnly: 'Please enter letters only.',
  enterEmail: 'Please enter your Email Address',
  invalidEmail: 'Please enter a valid Email Address',
  undeliverableEmail: 'Invalid Email or Domain. Please re-enter Email Address',
  invalidCVV: 'Invalid cvc',
  invalidPhoneNumber: 'Please enter a valid phone number',
  min13Length: 'Minimum 13 digits are required',
  onlyDigitsAllowed: 'Only digits are allowed',
  onlyAlphaNumeric: 'Only letters and digits allowed',
  min3Length: 'Minimum 3 digits are required',
  min2Length: 'Minimum 2 characters are required',
  min10Length: 'Minimum 10 digits are required',
  answersMatch: 'All the answers should be different',
  invalidCCN: 'Card is Invalid',
  invalidUserid: 'User ID should be greater than 3',
  invalidExpDate: 'Invalid expiration date',
  notOnlyNumerics: 'Numerics alone are not allowed',
  noPrepaids: 'Prepaid cards are not allowed',
  notValidCard: 'Invalid card details',
  notAcceptedCard: 'Invalid Card. EarthLink accepts the following: Visa, MasterCard, American Express and Discover.',
  businessRequired: 'Please enter a value greater than 0',
};
