import React from 'react';
import * as $ from 'axios';
import { Select } from 'antd';
import { isUndefinedNullOrEmpty, getAggregatedAddress, aggregateAddressAndSec } from '../../common/common';
import { getConfigs } from '../../config'
import 'antd/es/select/style/index.css';

const SmartStreets = getConfigs().SMARTY_API_KEY

const { Option } = Select;
export default class AddressSuggestionSelect extends React.Component {
  state = {
    data: [],
    value: isUndefinedNullOrEmpty(this.props.val) ? '' : this.props.val,
    addressProps: [],
    multipleEntries: false,
    globalDate: null,
  };

  componentDidMount() {
    const addressInput = document.getElementById('addressInput');
    addressInput.onpaste = function (e) {
      e.preventDefault();
    }
  }

  handleSearch = (value) => {
    let suggestionsArray = [];
    let addressArray = [];
    if (value.trim().length && value.length > 3) {
      if (value.length === 4 || (this.state.globalDate && (Date.now() - this.state.globalDate) / 1000 >= .5)) {
        $.get(`https://us-autocomplete-pro.api.smartystreets.com/lookup?search=${value}&key=${SmartStreets}&source=all`)
          .then((data) => {
            if (data.data.suggestions) {
              data.data.suggestions.forEach((suggestion, index) => {
                const { street_line:streetLine, secondary, city, state, zipcode, entries } = suggestion;
                suggestionsArray[index] = aggregateAddressAndSec({
                  'address1': streetLine,
                  'address2': secondary,
                  'entries': entries,
                  'city': city,
                  'state': state,
                  'zipCode': zipcode,
                });
                addressArray[index] = {
                  'address1': streetLine,
                  'address2': secondary,
                  'city': city,
                  'state': state,
                  'zip': zipcode,
                }
                this.setState({
                  data: suggestionsArray,
                  addressProps: addressArray,
                  value: '',
                  globalDate: Date.now(),
                });
              })
            } else if (data.data.suggestions === null) {
              $.get(`https://us-autocomplete.api.smartystreets.com/suggest?prefix=${value}&key=${SmartStreets}&source=all`)
                .then((addressApiRes) => {
                  if (addressApiRes.data.suggestions) {
                    addressApiRes.data.suggestions.forEach((suggestion) => {
                      const { street_line:streetLine, city, state } = suggestion
                      $.get(`https://us-street.api.smartystreets.com/street-address?candidates=10&match=invalid&key=${SmartStreets}&street=${encodeURIComponent(streetLine)}&city=${city}&state=${state}&source=all`)
                        .then((response) => {
                          if (!isUndefinedNullOrEmpty(response.data[0].components.zipcode)) {
                            const {
                              primary_number:primaryNumber = '',
                              street_name:streetName = '',
                              street_suffix:streetSuffix = '',
                              secondary_number:secondaryNumber = '',
                              secondary_designator:secondarDesignator = '',
                              city_name:cityName = '',
                              state_abbreviation:stateAbbreviation = '',
                              zipcode,
                            } = response.data[0].components;

                            let address1, address2 = '';

                            if (primaryNumber || streetName || streetSuffix) {address1 = `${primaryNumber} ${streetName} ${streetSuffix}`}
                            if (secondarDesignator || secondaryNumber) {address2 = `${secondarDesignator} ${secondaryNumber}`}

                            // CHECK IF INCLUDES STATE AND ZIP
                            if (address2.includes(stateAbbreviation) || address2.includes(zipcode)) {
                              address2 = ''
                            }

                            // SET SUGGESTIONS
                            suggestionsArray.push(aggregateAddressAndSec({
                              'address1': address1,
                              'address2': address2,
                              'entries': 0,
                              'city': cityName,
                              'state': stateAbbreviation,
                              'zipCode': zipcode
                            }));
                            addressArray.push({
                              'address1': address1,
                              'address2': address2,
                              'city': cityName,
                              'state': stateAbbreviation,
                              'zip': zipcode
                            });
                            this.setState({
                              data: suggestionsArray,
                              addressProps: addressArray,
                              value: '',
                              globalDate: Date.now()
                            });
                          }
                        })
                    });
                  }
                })
            }
          })
      }
    }
  };

  handleChange = (value) => {
    if (!isUndefinedNullOrEmpty(value)) {
      const selectedAddress = this.state.data[value].address;
      if (selectedAddress.includes('more entries')) {
        let multipleAdd = true;
        const modifiedAddress = selectedAddress.replace(' more entries', '');
        const suggestionsArray = [];
        let addressArray = [];
        $.get(`https://us-autocomplete-pro.api.smartystreets.com/lookup?search=${modifiedAddress}&selected=${modifiedAddress}&key=${SmartStreets}`)
          .then((data) => {
            if (data.data.suggestions) {
              data.data.suggestions.forEach((suggestion, index) => {
                const { street_line:streetLine, secondary, city, state, zipcode } = suggestion;
                suggestionsArray[index] = getAggregatedAddress({
                  'address1': streetLine,
                  'address2': secondary,
                  'city': city,
                  'state': state,
                  'zipCode': zipcode
                }, true);
                addressArray[index] = {
                  'address1': streetLine,
                  'address2': secondary,
                  'city': city,
                  'state': state,
                  'zip': zipcode
                }
              })
            }
            this.setState({
              value: '',
              data: suggestionsArray,
              addressProps: addressArray,
              multipleEntries: multipleAdd
            });
          })
      } else {
        this.setState({
          value,
          multipleEntries: false
        });
        if (this.props.setSearchAddress) {
          this.props.setSearchAddress(selectedAddress, this.state.addressProps[value]);
        }
        if (this.props.setBilling) {
          this.props.setBilling(this.state.addressProps[value]);
        }
        if (this.props.setShipping) {
          this.props.setShipping(this.state.addressProps[value]);
        }
      }
    } else {
      this.setState({ data: [] })
    }
  };

  render() {
    const { data, value, multipleEntries } = this.state;
    const selectOptions = {
      filterOption: false,
      notFoundContent: null,
      onChange: this.handleChange,
      onSearch: this.handleSearch,
      showAction: ['click'],
      showArrow: false,
      showSearch: true,
      size: 'large',
      value: value,
    }
    if (multipleEntries) {
      selectOptions.open = true
    }

    return (
      <Select
        id="addressInput"
        onInputKeyDown={e => e.key === 'Enter' && this.props.handleSubmit}
        {...selectOptions}
      >
        <Option selected hidden key="default" value="">
          <span className="placeholder">

            {
              this.props.parentComp === 'servCheck' ?
                ' Enter Street Address' : this.props.parentComp === 'payment' ?
                  'Billing Address' : this.props.parentComp === 'shipping' ? 'Shipping Address' : 'Enter Address'
            }
          </span>
        </Option>
        {
          data !== [] && data.map((d, i) =>
            <Option
              className={d.hasMore && 'boldText'}
              key={i}>
              {d.address}
            </Option>
          )}
      </Select>
    );
  }
}
