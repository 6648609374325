import React from 'react';
import { Card, Row, Col } from 'antd';
import { v4 as uuid } from 'uuid';
import {
  checkoutElnkPerks,
  checkoutOurPromise,
  earthlinkPerks,
  hsiBestIsp,
  hsiBestOverall,
  hsiCustomerSatisfaction,
} from '../../../images';
import PropTypes from 'prop-types';
export const EarthlinkPerks = (props) => {
  const { showCallNow } = props;
  const cardData = [
    {
      image: `${checkoutElnkPerks}`,
      title: 'It pays to choose EarthLink',
      items: [
        'Join EarthLink Perks for free!',
        'Get access to hundreds of deals on your favorite retailers',
        'Save thousands with our exclusive discounts',
        'Only available to EarthLink customers',
      ],
      logos: [{ logo: `${earthlinkPerks}`, width: '212px', height: '54px' }],
    },
    {
      image: `${checkoutOurPromise}`,
      title: 'Our promise to you',
      items: [
        'Locked-in price for 12 months',
        'Unlimited data for any fiber plan',
        '24/7 customer support',
        'No. 1 in customer satisfaction**',
      ],
      logos: [
        { logo: `${hsiBestIsp}`, width: '84px', height: '80px' },
        { logo: `${hsiBestOverall}`, width: '80px', height: '80px' },
        { logo: `${hsiCustomerSatisfaction}`, width: '60px', height: '80px' },
      ],
    },
  ];
  return (
    <section className="earthlink-perks-section">
      <Row gutter={30}>
        {cardData?.map((card) => (
          <Col key={uuid()} xs={24} sm={24} md={showCallNow ? 12 : 24} lg={12}>
            <Card className="card">
              <div className="image-container">
                <img className="card-image" src={card.image} alt={card.title} />
                <div className="logo-images">
                  {card?.logos.map((item) => {
                    return (
                      <img
                        key={uuid()}
                        className="logo-image"
                        src={item.logo}
                        width={item.width}
                        height={item.height}
                        alt={card.title}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="card-content">
                <h2>{card.title}</h2>
                <ul>
                  {card?.items?.map((item) => (
                    <li key={uuid()}>{item}</li>
                  ))}
                </ul>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
};
EarthlinkPerks.propTypes = {
  showCallNow: PropTypes.bool.isRequired,
};
