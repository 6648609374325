import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import {
  setPromoCode,
  MOBILE_URL,
} from '../../common/common';
import { OrderStatus, validMobileproducts } from '../../util/util';
import { useDispatch } from 'react-redux';
import { useMbox, useSessionData } from '../../hooks/order';
import { getMobileProducts, getServicePropsByCode, saveFirstStep, saveWirelessPlanInfo, sessionCall, setMBoxReqObj, setMobileOnlyContainer, updateOrder } from '../../store/action/OrderAction';
import Checkout from './Checkout/CheckOut';
import { BackdropLoader } from '../common/Loader';
import { useLocation } from 'react-router-dom';
import useLeadsSDK from '../../hooks/useLeadsSDK';

const MobileOnly = () => {
  const leads = useLeadsSDK();
  const dispatch = useDispatch();
  const location = useLocation();
  const { sessionResponse, orderInfo } = useSessionData();
  const { id } = orderInfo;
  const { mobileServRef = '', gid } = queryString.parse(location.search);
  const promoCode = sessionResponse && sessionResponse.promoCode ? sessionResponse.promoCode : null;
  const agentId = sessionResponse && sessionResponse.agentId ? sessionResponse.agentId : null;
  const [loading, setLoading] = useState(true);
  const { mboxRequired } = useMbox();
  const getOccurrence = (array, value) => {
    return array.filter((v) => (v === value)).length;
  }

  useEffect(() => {
    if (!Object.keys(sessionResponse).length) {
      dispatch(sessionCall(gid)).then().catch(() => {
        window.location.href = MOBILE_URL;
      });
    }
    const WirelessServRef = mobileServRef && mobileServRef.split(',');
    if (!mobileServRef || (WirelessServRef.length > 0 && !WirelessServRef.every(el => validMobileproducts.includes(el)))) {
      window.location.href = MOBILE_URL;
    }
    if (agentId) {
      setPromoCode(promoCode)
      dispatch(getMobileProducts(promoCode)).then(async (res) => {
        const products = res.value.data.filter(element => WirelessServRef.includes(element.servRef));
        if (products && products.length > 0) {
          const wirelessCart = products.map(product => {
            product.quantity = getOccurrence(WirelessServRef, product.servRef);
            dispatch(getServicePropsByCode(product.servCode)).then((response) => {
              mboxRequired[product.servCode] = (response.action?.payload?.data?.item?.isMboxReq);
            });
            dispatch(setMBoxReqObj(mboxRequired));
            return product;
          })
          dispatch(saveWirelessPlanInfo({
            wirelessPlanInfo: wirelessCart,
            wirelesServeRef: WirelessServRef.toString(),
          }));
          dispatch(
            updateOrder({ orderStatus: OrderStatus.addedToCart, serviceReference: WirelessServRef.toString() }, id)
          );
          leads?.updateOrder?.(
            'Update Order: Add Product',
            {
              products: [
                {
                  data: {
                    category: 'mobile',
                  },
                  id: String(WirelessServRef),
                  quantity: 1,
                },
              ],
            },
          );
          dispatch(setMobileOnlyContainer(true));
          dispatch(saveFirstStep(true));
          setLoading(false);
        } else {
          setLoading(false);
          window.location.href = MOBILE_URL;
        }
      }).catch(() => { setLoading(false); window.location.href = MOBILE_URL; });
    }
  }, [sessionResponse]);


  return (
    <div>
      {loading && <BackdropLoader />}
      {!loading && <Checkout />}
    </div>
  )
}

export default MobileOnly;
