import React from 'react';
import Protect from '../vas/Protect';
import ProtectPlus from '../vas/ProtectPlus';
import OnlineBackup from '../vas/OnlineBackup';
import Guardian from '../vas/Guardian';
import EasyTech from '../vas/EasyTech';

const VasStatic = () => {
  if (typeof window.renderWPpage === 'function') {
    window.renderWPpage(false);
  }
  if (typeof window.showCartButton === 'function') {
    window.showCartButton(false);
  }

  return (
    <div className="elink-container responsive-w-100 p-top-5">
      <Protect />
      <ProtectPlus />
      <OnlineBackup />
      <Guardian />
      <EasyTech />
    </div>
  )
}
export default VasStatic;
