export const sessionKeys = {
  accessToken: 'accessToken',
  affiliateID: 'affiliateID',
  agentId: 'agentId',
  callLogId: 'callLogId',
  cjevent: 'cjevent',
  custId: 'custId',
  isHome: 'isHome',
  nbc: 'nbc',
  promoCode: 'promoCode',
  refreshToken: 'refreshToken',
  securityAns: 'securityAns',
  securityQue: 'securityQue',
  sessionOrderId: 'sessionOrderId',
  tfn: 'tfn',
  HomeUrl: 'homeUrl'
};
