import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkServiceability,validateAddressServiceability, resetState, saveBuyflow, saveServiceCheckId, serviceCheck, sessionCall, setTFN, updateIsBusinessAddress, updateOrder } from '../../store/action/OrderAction';
import queryString from 'query-string';
import { useLoading, useServiceablitityData, useSessionData } from '../../hooks/order';
import { routesConfig, OrderStatus, referrerUris } from '../../util/util';
import {
  getSegregatedAddress,
  setCjevent,
  isUndefinedNullOrEmpty,
  getAggregatedAddress,
  getBaseUrl,
} from '../../common/common';
import { getConfigs } from '../../config';
import { getCheckServiceableModel, getServiceCheckModel } from '../../helpers/OrderHelperFunctions';
import { BackdropLoader, Loader } from '../common/Loader';
import AddressSuggestions from './AddressSuggestions';
import AddressSuggestionSelect from '../common/AddressSuggestionSelect';
import { Checkbox, Row, Col } from 'antd';
import VerifyAddressModal from './VerifyAddressModal';
import useLeadsSDK from '../../hooks/useLeadsSDK';
const config = getConfigs();

const ServiceabilityCheck = () => {
  const leads = useLeadsSDK();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sessionResponse, orderInfo, referrer } = useSessionData();
  const { normalizedAddress, callLogId } = useServiceablitityData();
  const { isBusiness = false, addr = '', isMobile = false, isInternet = false, cjevent = '', leadId = '' } = queryString.parse(location.search);
  const [initialAddress, changeAddress] = useState(getSegregatedAddress(addr));
  const gid = (sessionResponse && sessionResponse.uid) || queryString.parse(location.search).gid;
  const referrerUri = (referrer) || queryString.parse(location.search).referrer;
  const { agentId } = sessionResponse;
  const cjeve = (sessionResponse && sessionResponse.cjevent) || cjevent;
  const [suggestedAddr, setsuggestedAddresses] = useState('');
  const [searchAddress, setSearchAddress] = useState(addr);
  const [loadingState, setLoading] = useState(true);
  const [validAddr, setValidAddr] = useState(false);
  const [addressFlag , setAddressFlag] = useState(false);
  const [businessAddress, setBusinessAddr] = useState(isBusiness ? isBusiness === 'true' : isBusiness);
  const [internetAddress, setInternetAddr] = useState(isInternet ? isInternet === 'true' : isInternet);
  const [mobileAddress, setMobileAddr] = useState(isMobile ? isMobile === 'true' : isMobile);
  const [nonServiceTFN]  = referrerUri ? referrerUris[referrerUri] : ''
  const showMobileOptions = config.SHOW_MOBILE_OPTIONS === 'true';

  useEffect(() => {
    if (Object.keys(sessionResponse)?.length === 0 && !isUndefinedNullOrEmpty(gid)) {
      dispatch(sessionCall(gid)).catch(() => {
        window.location.href = getBaseUrl();
      })
    }
    if (cjeve) {
      setCjevent(cjeve);
    }
    setLoading(false)
    dispatch(setTFN('default'))
    // if (referrerUri) {
    //   dispatch(setCustomTFN(serviceTFN));
    //   dispatch(setCustomWhiTFN(whiServiceTFN));
    // }
  }, [Object.keys(sessionResponse).length]);

  useEffect(() => {
    if (addr && orderInfo && Object.keys(orderInfo).length) {
      handleSubmit(addr)
    } else {
      setInternetAddr(true)
    }
  }, [Object.keys(orderInfo).length])

  const redirectToResults = () => {
    if(!isUndefinedNullOrEmpty(referrerUri)){
      window.location.href = config.RESULTS_URL + '?address=' + searchAddress + '&gid=' + gid + '&tfn=' + nonServiceTFN;
    }else{
      navigate(routesConfig.nonServiceable);
    }
  }

  useEffect(() => {
    if(searchAddress){
      dispatch(validateAddressServiceability({'address' : searchAddress})).then(res => {
        if(res?.value?.data?.dpvFootnotes === 'H#' || res?.value?.data?.dpvMatchCode === 'D'){
          setAddressFlag(true)
        }
      }).catch(() => {
        setAddressFlag(false);
      })
    }
  },[searchAddress])
  const resetClick = () => {
    dispatch(resetState());
  }

  const handleNotSeen = () => {
    redirectToResults();
  }

  const handleSubmit = (searchAddr = searchAddress, suggestedAddress = false) => {
    const orderId = (orderInfo && orderInfo.id) || '';
    const id = sessionResponse && sessionResponse.id ? sessionResponse.id : null;
    const promoCode = !isUndefinedNullOrEmpty(sessionResponse.promoCode) ? sessionResponse.promoCode : null;
    const businessPromoCode = sessionResponse && sessionResponse.businessPromoCode ? sessionResponse.businessPromoCode : null;
    const partnerId = sessionResponse && sessionResponse.partnerId ? sessionResponse.partnerId : null;
    const { address1 = '', address2 = '', city = '', state = '', zip = '' } = initialAddress;
    if (isUndefinedNullOrEmpty(orderId)) {
      return;
    }

    /**
     * Initialize the Lead
     */

    if (!isUndefinedNullOrEmpty(leadId)) {
      leads?.initLeadFromId?.(leadId);
    } else if (!isUndefinedNullOrEmpty(addr)) {
      leads?.initLeadFromAddress?.(address1, address2, city, state, zip);
    }

    if (!isUndefinedNullOrEmpty(id)) {
      const checkServiceabilityModel = getCheckServiceableModel(searchAddr, suggestedAddress, businessAddress, promoCode, partnerId, businessPromoCode);
      let serviceCheckmodel = getServiceCheckModel(searchAddr, address1, address2, city, state, zip, agentId, null);
      dispatch(updateIsBusinessAddress(businessAddress));
      dispatch(checkServiceability(checkServiceabilityModel))
        .then(async (res) => {
          const { data } = res.value
          const { products, suggestedAddresses, nbcScore, mobileProducts, fixedWirelessProducts } = res.value.data;
          if (mobileAddress === true && internetAddress === false && mobileProducts?.length === 0) {
            redirectToResults(suggestedAddress ? getAggregatedAddress(suggestedAddress) : searchAddress);
            return;
          } else if ((mobileAddress === true && internetAddress === true) || (mobileAddress === true && businessAddress === true) || (!mobileAddress === true && internetAddress === false && businessAddress === false)) {
            dispatch(saveBuyflow('all'))
          } else if (internetAddress === true || businessAddress === true) {
            if (products.length > 0 || fixedWirelessProducts.length > 0) {
              dispatch(saveBuyflow('internet'))
            } else {
              dispatch(saveBuyflow('otherMobile'))
            }
          } else if (mobileAddress === true && mobileProducts.length > 0) {
            if (mobileProducts.length > 0) {
              dispatch(saveBuyflow('wireless'))
            } else {
              dispatch(saveBuyflow('otherInt'))
            }
          }
          serviceCheckmodel.nbcScore = nbcScore;
          if (suggestedAddresses.length && !products.length) {
            setsuggestedAddresses(suggestedAddresses);
            setLoading(false);
          } else if (products.length || mobileProducts.length || fixedWirelessProducts.length) {
            serviceCheckmodel.productList = products;
            dispatch(serviceCheck(serviceCheckmodel)).then((response) => {
              dispatch(saveServiceCheckId(response.value.data.item.id))
              dispatch(updateOrder({
                billingAddress: '',
                nbcScore,
                orderStatus: OrderStatus.serviceCheck,
                serviceCheckId: response.value.data.item.id,
                serviceAddress: getAggregatedAddress(data.normalizedAddress),
                serviceAdd1: data.normalizedAddress.address1,
                serviceAdd2: data.normalizedAddress.address2,
                serviceCity: data.normalizedAddress.city,
                serviceState: data.normalizedAddress.state,
                serviceZip: data.normalizedAddress.zipCode,
                callLogId: data.callLogId,
              }, orderId));
            }).then(() => {
              setLoading(false);

              /**
               * Check address serviceability.
               */
              leads?.addressServiceable?.('Check Serviceability', {
                address1: data?.normalizedAddress?.address1,
                address2: data?.normalizedAddress?.address2,
                city: data?.normalizedAddress?.city,
                data: {
                  adslShippingCost: data?.adslShippingCost,
                  callLogId: data?.callLogId,
                  coverageStrength: {
                    fixedWireless:
                      data?.fixedWireless?.coverageStrength ?? 'N/A',
                    mobile: data?.mobile?.coverageStrength ?? 'N/A',
                  },
                  extendedInfo: {
                    serviceableService:
                      data?.extendedInfo?.serviceableService?.map((i) => i) ??
                      [],
                  },
                  nbcScore: data?.nbcScore,
                  products: {
                    fixedWireless: data?.fixedWirelessProducts?.map((i) => ({
                      id: String(i?.servRef),
                      name: i?.serviceName,
                    })),
                    mobile: data?.mobileProducts?.map((i) => ({
                      id: String(i?.servRef),
                      name: i?.serviceName,
                    })),
                    internet: data?.products?.map((i) => ({
                      id: String(i?.servRef),
                      name: i?.serviceName,
                    })),
                    vas: data?.vasProducts?.map((i) => ({
                      id: String(i?.servRef),
                      name: i?.serviceName,
                    })),
                  },
                },
                state: data?.normalizedAddress?.state,
                zip: data?.normalizedAddress?.zipCode,
                serviceable: true,
              });

              /**
               * Update order service address.
               */
              leads?.updateOrder?.(
                'Update Order: Serviceability Details',
                {
                  account: {
                    serviceAddress: {
                      address1: data?.normalizedAddress?.address1,
                      address2: data?.normalizedAddress?.address2,
                      city: data?.normalizedAddress?.city,
                      state: data?.normalizedAddress?.state,
                      zip: data?.normalizedAddress?.zipCode,
                      serviceable: 'Y',
                    },
                  },
                  data: {
                    promoCode,
                  }
                }
              );
            }).catch(() => setLoading(false));

            navigate(routesConfig.products);
          } else {
            await dispatch(serviceCheck(serviceCheckmodel)).then(async (response) => {
              dispatch(updateOrder({
                billingAddress: '',
                nbcScore,
                orderStatus: OrderStatus.unserviceable,
                serviceCheckId: response.value.data.item.id,
                serviceAddress: getAggregatedAddress(data.normalizedAddress),
                serviceAdd1: data.normalizedAddress.address1,
                serviceAdd2: data.normalizedAddress.address2,
                serviceCity: data.normalizedAddress.city,
                serviceState: data.normalizedAddress.state,
                serviceZip: data.normalizedAddress.zipCode,
                callLogId: data.callLogId,
              }, orderId)).then(() => {
                setLoading(false)
                leads?.setDispositionClosed?.('Close Lead: Not Serviceable', {
                  data: {
                    reason: 'Not Serviceable',
                  },
                });
              }).catch(() => setLoading(false))
            });
            navigate(routesConfig.nonServiceable);
          }
        }).catch(async () => {
          await dispatch(serviceCheck(serviceCheckmodel)).then(async (response) => {
            dispatch(updateOrder({
              billingAddress: '',
              orderStatus: OrderStatus.unserviceable,
              serviceCheckId: response.value.data.item.id,
              serviceAddress: getAggregatedAddress(normalizedAddress),
              serviceAdd1: normalizedAddress.address1,
              serviceAdd2: normalizedAddress.address2,
              serviceCity: normalizedAddress.city,
              serviceState: normalizedAddress.state,
              serviceZip: normalizedAddress.zipCode,
              callLogId,
            }, orderId)).then(() => {
              setLoading(false)
              leads?.setDispositionClosed?.('Close Lead: Not Serviceable', {
                data: {
                  reason: 'Not Serviceable',
                },
              });
            }).catch(() => setLoading(false))
          });
          redirectToResults(searchAddress);
        });
    } else {
      redirectToResults(searchAddress);
    }
  }

  const setAddress = (getSearchAddress, addressObject) => {
    setSearchAddress(getSearchAddress);
    setValidAddr(true);
    changeAddress(addressObject);
  }

  const { loading } = useLoading();

  const handleClose = () => {
    setAddressFlag(false);
  };

  if (suggestedAddr.length && Object.keys(sessionResponse).length) {
    return (
      <AddressSuggestions
        onNotSeenClick={handleNotSeen}
        onChangeAddressClick={resetClick}
        onAddressClick={handleSubmit}
        className="userInput"
      />
    )
  }
  if (!queryString.parse(location.search).addr && Object.keys(sessionResponse).length) {
    return (
      <>
        <div className="elink-container mt-5 serviceabilityCheck p-top-5">
          <div className="card">
            <div className="inner-card">
              <h2 className="text-center">
                Check Availability or Call to Order EarthLink
              </h2>
              <VerifyAddressModal isModalOpen={addressFlag} handleClose={handleClose} handleSubmit={handleSubmit} searchAddress={searchAddress}/>
              <label htmlFor="addressInput" className="hidden-label">address</label>
              <Row>
                <Col span={24}>
                  <AddressSuggestionSelect
                    autoFocus={true}
                    loading={loadingState}
                    setSearchAddress={setAddress}
                    className="userInput"
                    parentComp="servCheck"
                  />
                </Col>
              </Row>
              <Row className="m-top-1" align="middle">
                <Col span={12} className="responsive-width-set">
                  <div className="addres-labels">
                    <div className="d-flex j-center">
                      <i className="fas fa-wifi"></i>
                      {
                        sessionResponse?.businessFlag &&
                        <Checkbox
                          name="internetAddress"
                          onChange={() => { setInternetAddr(!internetAddress) }}
                          checked={internetAddress}
                        >
                          <b>Show Internet Plans</b>
                        </Checkbox>
                      }
                    </div>
                    {showMobileOptions && <div className="d-flex j-center">
                      <i className="fas fa-mobile-alt"></i>
                      {sessionResponse?.businessFlag && (
                        <Checkbox
                          name="MobileAddress"
                          onChange={() => {
                            setMobileAddr(!mobileAddress);
                          }}
                          checked={mobileAddress}
                        >
                          <b>Show Mobile Plans</b>
                        </Checkbox>
                      )}
                    </div>}
                    <div className="d-flex j-center">
                      <i className="fas fa-suitcase"></i>
                      {
                        sessionResponse?.businessFlag &&
                        <Checkbox
                          name="businessAddress"
                          onChange={() => { setBusinessAddr(!businessAddress) }}
                          checked={businessAddress}
                        >
                          <b>Business Address</b>
                        </Checkbox>
                      }
                    </div>
                  </div>

                </Col>
                {/* row sen */}
                <Col span={12} className="responsive-width-set responsive-top-m loader-height-auto">
                  {
                    loading ? <Loader /> :
                      <button
                        type="submit"
                        className="blue-button"
                        onClick={() => handleSubmit(searchAddress, false)}
                        disabled={!validAddr || (!internetAddress && !mobileAddress && !businessAddress)}
                      >
                        <div className="btn-color-darkblue">
                          Check Availability
                          <i className="fas fa-chevron-circle-right" />
                        </div>
                      </button>
                  }
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <p className="disclaimer-text text-right text-black">
                    Product availability and speed varies by address.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <BackdropLoader />
  )
}

export default React.memo(ServiceabilityCheck);
