import React, { useState, useEffect } from 'react';
import { Row, Col, Calendar } from 'antd';
import moment from 'moment-timezone';
import { convertDateTimeToUTC, convertInstallWindowToReadableString, isEmpty } from '../../../common/common';
import { useDispatch } from 'react-redux';
import { saveScheduleDateTime } from '../../../store/action/OrderAction';
import { useScheduleDate } from '../../../hooks/order';
moment.tz.setDefault('America/New_York');

const ScheduleInstallation = (props) => {
  const dispatch = useDispatch();
  const { scheduleAppt, isMobileAdded } = props;
  const { scheduleDateTime = '' } = useScheduleDate();
  const dateTime = scheduleDateTime ? convertInstallWindowToReadableString(scheduleDateTime) : '';
  const [reqInstallDate = '', reqInstallTime = ''] = dateTime ? dateTime.split('between') : [];

  const finalObj = {};
  const [state, setState] = useState({
    timeSlots: [],
    isSelectedTime: reqInstallTime ? reqInstallTime?.replace('and', 'to')?.trim() : '',
    calenderSelectedDate: reqInstallDate ? moment.utc(reqInstallDate).format('YYYY-MM-DD') : ''
  })
  const { timeSlots, isSelectedTime, calenderSelectedDate } = state;

  useEffect(() => {
    if (reqInstallDate.length !== 0 && reqInstallTime?.length !== 0) {
      setState({ ...state, calenderSelectedDate: moment.utc(reqInstallDate).format('YYYY-MM-DD'), isSelectedTime: reqInstallTime?.replace('and', 'to')?.trim() })
    }
  }, [reqInstallDate, reqInstallTime])

  if (scheduleAppt && scheduleAppt.length) {
    scheduleAppt.map((items) => {
      const getScheduleData = items.split('|');
      const date = moment(getScheduleData[0]).format('YYYY-MM-DD');
      const getDateTime = getScheduleData.filter((splitTime) => splitTime !== '0');
      const startTime = `${moment.utc(getDateTime[0]).format('h:mm a')} to ${moment
        .utc(getDateTime[1])
        .format('h:mm a')}`;
      if (finalObj[date]) {
        finalObj[date].push(startTime);
      } else {
        finalObj[date] = [startTime];
      }
      return true;
    });
  }

  const getTimeSlots = (date) => {
    return finalObj &&
      finalObj[date] &&
      finalObj[date].map((item) => {
        if (item) {
          return item;
        }
        return false;
      });
  }

  const onSelect = (date) => {
    const selectedDate = moment(date).format('YYYY-MM-DD');
    setState({
      ...state,
      timeSlots: getTimeSlots(selectedDate),
      calenderSelectedDate: selectedDate,
      isSelectedTime: ''
    })
  };

  useEffect(() => {
    if (scheduleDateTime?.length === 0 && (calenderSelectedDate === '' || timeSlots?.length === 0)) {
      setState({ ...state, calenderSelectedDate: Object.keys(finalObj)[0], timeSlots: getTimeSlots(Object.keys(finalObj)[0]) });
    }
  }, [calenderSelectedDate, finalObj, getTimeSlots, scheduleDateTime]);

  useEffect(() => {
    if (reqInstallDate.length !== 0) {
      setState({ ...state, timeSlots: getTimeSlots(moment.utc(reqInstallDate).format('YYYY-MM-DD')) });
    }
  }, [reqInstallDate]);

  function disabledDate(current) {
    const disabledDates = Object.keys(finalObj).find((o) => {
      if (moment(o).format('YYYY-MM-DD') === moment(current).format('YYYY-MM-DD')) {
        return true;
      }
      return false;
    });

    if (disabledDates) {
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    let combineDate = `${moment(calenderSelectedDate).format('YYYY-MM-DD')} to ${isSelectedTime}`
    dispatch(saveScheduleDateTime(convertDateTimeToUTC(combineDate)))
    props.setActiveKey(isMobileAdded ? '2' : '3')
  }

  const handleNoThanks = () => {
    dispatch(saveScheduleDateTime(''))
    props.setActiveKey(isMobileAdded ? '2' : '3')
  }

  const getInitialDate = (selectedMon) => {
    for (let key in finalObj) {
      let d = moment(key);
      let n = d.format('M');
      if (parseInt(n) === selectedMon) {
        return key
      }
    }
  }

  return (
    <>
      <div className="calender-row">
        <Row>
          <Col span={12} className="main-calender responsive-width-set">
            <h3 className="text-center under-tab-heading">Appointment Preference</h3>
            <div className="site-calendar-demo-card">
              <Calendar
                fullscreen={false}
                headerRender={({ value, onChange }) => {
                  let start = Number(moment(Object.keys(finalObj)[0]).format('MM')) - 1;
                  let end = Number(moment(Object.keys(finalObj)[Object.keys(finalObj).length - 1]).format('MM'),);
                  const monthOptions = [];
                  const current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.months(current));
                  }
                  if (start > end) {
                    for (let index = start; index < 12; index++) {
                      monthOptions.push(index);
                    }
                    start = 0
                  }
                  for (let index = start; index < end; index++) {
                    monthOptions.push(index);
                  }
                  const month = moment(value).format('MMMM');
                  const handleChangeMonth = (op) => {
                    let selectedMonth;
                    if (op === '+') {
                      selectedMonth = (value.month() + 1) % 12;
                    } else if (op === '-') {
                      selectedMonth = value.month() ? (value.month() % 12) - 1 : 11;
                    } else if (op === 'last') {
                      selectedMonth = monthOptions[monthOptions.length - 1];
                    } else if (op === 'first') {
                      selectedMonth = monthOptions[0];
                    }
                    selectedMonth = Math.abs(selectedMonth);
                    if (monthOptions.includes(selectedMonth)) {
                      // Set the new month
                      const newValue = value.clone();
                      newValue.month(selectedMonth);
                      onChange(newValue);
                      // Get Initial Date for new month selected
                      const initialDate = getInitialDate(selectedMonth + 1);
                      onSelect(initialDate);
                    }
                  }
                  return (
                    <div>
                      <Row className="schedule-header mb-2">
                        <Col span={5}>Month: &nbsp; </Col>
                        <Col span={1}>
                          <i className={`fa-solid fa-angles-left ${value.month() === monthOptions[0] ? 'hide-items' : 'titleColor show-items'}`} aria-hidden="true" onClick={() => handleChangeMonth('first')}></i>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                          <i className={`fa-solid fa-angle-left ${value.month() === monthOptions[0] ? 'hide-items' : 'titleColor show-items'}`} aria-hidden="true" onClick={() => handleChangeMonth('-')}></i>
                        </Col>
                        <Col span={10} className="text-center">
                          <span>{month}</span>
                        </Col>
                        <Col span={1}>
                          <i className={`fa-solid fa-angle-right ${value.month() === monthOptions[monthOptions.length - 1] ? 'hide-items' : 'titleColor show-items'}`} aria-hidden="true" onClick={() => handleChangeMonth('+')}></i>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                          <i className={`fa-solid fa-angles-right ${value.month() === monthOptions[monthOptions.length - 1] ? 'hide-items' : 'titleColor show-items'}`} aria-hidden="true" onClick={() => handleChangeMonth('last')}></i>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
                disabledDate={disabledDate}
                onSelect={onSelect}
                value={moment(calenderSelectedDate)}
              />
            </div>
          </Col>
          <Col span={12} className="main-arrival-wnidow responsive-width-set">

            <h3 className="text-center under-tab-heading">Technician&apos;s Arrival Window</h3>
            {timeSlots &&
              timeSlots.map((timeVal, i) => {
                return (
                  <>
                    <div className="text-center arrival-window-button">
                      <button
                        key={i}
                        onClick={() => {
                          setState({
                            ...state,
                            isSelectedTime: timeVal
                          })
                        }}
                        className={timeVal === isSelectedTime ? 'blue-border' : ''}
                      >
                        {timeVal === isSelectedTime ? <i className="fa fa-check-circle check-icon-green" aria-hidden="true"></i> : ''}
                        {timeVal}
                      </button>
                    </div>
                  </>
                );
              })}
            <div className="calender-below-buttons">
              <button className="blue-button grey-button" onClick={() => handleNoThanks()}>Skip</button>
              <button className="blue-button" onClick={() => handleSubmit()} disabled={isEmpty(isSelectedTime)}>Confirm</button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default ScheduleInstallation;
