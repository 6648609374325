import React, { useEffect } from 'react';
import AppRoutes from './routes/Routes';
import { getConfigs } from './config';

const { LEADS_SDK_SCRIPT_SRC } = getConfigs();

function App() {
  const config = getConfigs();

  // Redirect to headless checkout if partner request
  useEffect(() => {
    if (window.location.hash === '' && window.location.pathname.startsWith('/p/')) {
      const [,uri] = window.location.href.split('/p/');

      window.location.href = `${config.HEADLESS_CHECKOUT_BASE_URL}/#/partner/${uri}`;
    }
  }, []);

  /**
   * script tag for leads api sdk
   */
  useEffect(() => {
    if (LEADS_SDK_SCRIPT_SRC) {
      const script = document.createElement('script');
      script.src = LEADS_SDK_SCRIPT_SRC;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <AppRoutes />
  );
}

export default App;
