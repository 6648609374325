import { routesConfig } from '../util/util';
import { getValidIntCost } from './common';

let dataLayer = window.dataLayer || [];

export const pageLoad = (uid) => {
  dataLayer.push({
    'event': 'pageLoad',
    'language': 'en', // language selected based on the URL
    'userAuthenticated': 't', // logged-in or out state
    'userID': uid, // unique identifier for authenticated users (non PII)
    'page': window.location.href // full url of page including fragments
    // 'siteSection': window.location.href.substring(window.location.href.lastIndexOf('/') + 1), // section based on the user location in the primary nav. Scrub
    // 'siteSubSection': 'internet', // sub section based on the users location else null. Scrub apostrophes
    // 'siteSubSectionCategory': 'residential internet' // sub section category. Scrub apostrophes
  })
};

export const EECproductDetailView = (finalProd) => {
  dataLayer.push({ ecommerce: null });
  if (finalProd.length > 0) {
    let products = [];
    finalProd.forEach((product) => {
      let productRef = {
        name: product.serviceName,
        price: product.price,
        brand: 'earthlink',
        category: (product.technology).toLowerCase() === 'vas' || (product.technology).toLowerCase() === 'mobile' ? product.technology : 'internet',
        variant: product.pricePeriod,
      };
      products.push(productRef);
    });
    dataLayer.push({
      event: 'EECproductDetailView',
      ecommerce: {
        currencyCode: 'USD',
        detail: {
          actionField: { affiliation: 'earthlink' },
          products: products,
        },
      },
    });
  }
}

export const EECproductAddToCart = (product) => {
  dataLayer.push({ ecommerce: null })
  if (Object.keys(product).length > 0) {
    let productRef = []
    productRef.push({
      'name': product.serviceName, // Name or ID is required
      'price': product.price, // price presented on the page
      'brand': 'earthlink', // product line
      'category': (product.technology).toLowerCase() === 'vas' || (product.technology).toLowerCase() === 'mobile' ? product.technology : 'internet', // product category
      'variant': product.pricePeriod, // product variant
      'quantity': product.quantity ? product.quantity : 1
    });
    dataLayer.push({
      event: 'EECproductAddToCart',
      ecommerce: {
        currencyCode: 'USD',
        add: {
          actionField: { affiliation: 'earthlink' },
          products: productRef,
        },
      },
    });
  }
}

export const EECproductRemoveFromCart = (product) => {
  dataLayer.push({ ecommerce: null })
  let productRef = []
  if (Object.keys(product).length > 0) {
    productRef.push({
      'name': product.serviceName, // Name or ID is required
      'price': product.price, // price presented on the page
      'brand': 'earthlink', // product line
      'category': (product.technology).toLowerCase() === 'vas' || (product.technology).toLowerCase() === 'mobile' ? product.technology : 'internet', // product category
      'variant': product.pricePeriod, // product variant
      'quantity': product.quantity ? product.quantity : 1
    });
  }
  dataLayer.push({
    event: 'EECproductRemoveFromCart',
    ecommerce: {
      currencyCode: 'USD',
      remove: {
        actionField: { affiliation: 'earthlink' },
        products: productRef,
      },
    },
  });
}

export const EECCheckout = (allProducts) => {
  dataLayer.push({ ecommerce: null })
  let step = 0;
  const pathname = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  if (pathname === (routesConfig.vasPage).substring(1)) { step = 1; }
  if (pathname === (routesConfig.checkout).substring(1) || pathname.includes('servRef')) { step = 2; }
  if (pathname === (routesConfig.reviewOrder).substring(1)) { step = 3; }
  if (pathname === (routesConfig.thanks).substring(1)) { step = 4; }
  let products = []
  if (allProducts.length > 0) {
    allProducts.forEach(details => {
      products.push({
        'name': details.serviceName, // Name or ID is required
        'price': details.price, // price presented on the page
        'brand': 'earthlink', // product line
        'category': (details.technology).toLowerCase() === 'vas' || (details.technology).toLowerCase() === 'mobile' ? details.technology : 'internet', // product category
        'variant': details.pricePeriod, // product variant
        'quantity': details.quantity ? details.quantity : 1
      });
    })
    if (step > 0 && step <= 4) {
      dataLayer.push({
        event: 'EECCheckout',
        ecommerce: {
          currencyCode: 'USD',
          checkout: {
            actionField: { step, affiliation: 'earthlink' },
            products: products
          },
        },
      });
    }
  }
}

export const EECtransactionSuccess = (props) => {
  dataLayer.push({ ecommerce: null })
  const { id, total, isADSL, products = [], modemFee, isInternetAdded, isMobileAdded, installationCost, adslShippingCost, simCharge } = props;
  let ProductRef = []
  if (products.length > 0) {
    if (isInternetAdded) {
      ProductRef.push({
        'name': 'Modem Lease',
        'price': getValidIntCost(modemFee.toString()),
        'brand': 'earthlink',
        'category': 'Fee',
        'variant': 'Monthly',
        'quantity': 1,
      })
      if (!isADSL) {
        ProductRef.push({
          'name': 'Internet Installation',
          'price': getValidIntCost(installationCost.toString()),
          'brand': 'earthlink',
          'category': 'Fee',
          'variant': 'Monthly',
          'quantity': 1,
        })
      }
    }
    if (isMobileAdded) {
      ProductRef.push({
        'name': 'Sim Charge',
        'price': getValidIntCost(simCharge.toString()),
        'brand': 'earthlink',
        'category': 'Fee',
        'variant': 'Monthly',
        'quantity': 1,
      })
    }

    products.forEach(product => {
      ProductRef.push({
        'name': product.serviceName, // Name or ID is required
        'price': getValidIntCost(product.price), // price presented on the page
        'brand': 'earthlink', // product line
        'category': (product.technology).toLowerCase() === 'vas' || (product.technology).toLowerCase() === 'mobile' ? product.technology : 'internet', // product category
        'variant': product.pricePeriod, // product variant
        'quantity': product.quantity ? product.quantity : 1
      });
    });
  }
  window.dataLayer.push({
    event: 'EECtransactionSuccess',
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          id, // Transaction ID. Required
          'affiliation': 'earthlink', // brand
          'option': 'card',// payment type - visa, mc, paypal
          'revenue': total,// Total transaction value (incl. tax and shipping) - this should be the actual price being paid
          'tax': '0',
          'shipping': isADSL ? getValidIntCost(adslShippingCost.toString()) : '0',
          'coupon': '' // coupon code, if used
        },
        products: ProductRef,
      },
    },
  });
  window.dataLayer.push({
    'event': 'purchase',
    'transaction_id': id,
    'value': total
  });
}
