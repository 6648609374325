const UnitType = [
  { name: 'Apartment', code: 'APT' },
  { name: 'Building', code: 'BLDG' },
  { name: 'Lot', code: 'LOT' },
  { name: 'Space', code: 'SPC' },
  { name: 'Floor', code: 'FL' },
  { name: 'Suite', code: 'STE' },
  { name: 'Unit', code: 'UNIT' },
  { name: 'Room', code: 'RM' },
  { name: 'Department', code: 'DEPT' },
];
export default UnitType;
