import React from 'react';
import { v4 as uuid } from 'uuid';
export const Disclaimer = () => {
  const isWinterPromo = true;
  const disclaimer = isWinterPromo
    ? [
      '* Actual speeds may vary depending on the distance, line-quality, phone service provider, and number of devices used concurrently. All speeds not available in all areas. Exclusions like taxes & fees apply. Not available in all areas. Limited time offer; subject to change.',
      '** EarthLink was awarded the No. 1 Customer Satisfaction Award in 2020 and 2021 by <a href="http://highspeedinternet.com/">highspeedinternet.com</a>, in addition to being compared to the publicly measured providers in the 2021 American Customer Satisfaction Index (ACSI) survey of customers rating the reliability of speed and service consistency of their own internet service provider.',
    ]
    : [
      '* EarthLink was awarded the No. 1 Customer Satisfaction Award in 2020 and 2021 by highspeedinternet.com, in addition to being compared to the publicly measured providers in the 2021 American Customer Satisfaction Index (ACSI) survey of customers rating the reliability of speed and service consistency of their own internet service provider.',
      '** While supplies last. Void where prohibited and outside the U.S. Offer available to legal U.S. residents (incl. D.C.) who are 18 and older at time of purchase. To be eligible, you must make a Qualifying Purchase, activate the service and remain active for 3 months. Gift: $200 Prepaid Mastercard©. For complete terms and eligibility requirements visit <a href=\'https://earthlink.net/tcs\'>earthlink.net/tcs</a>. Sponsor: EarthLink LLC.',
    ];

  return (
    <div className="disclaimer-section">
      {disclaimer?.map((paragraph) => (
        <p key={uuid()} dangerouslySetInnerHTML={{ __html: paragraph }} />
      ))}
    </div>
  );
};
