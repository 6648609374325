import React from 'react';
import { Row, Col } from 'antd';

export const OnlineBackup = () => {
  return (
    <div className="online-backup-section border-same m-top-3 same-protect">

      <div className="online-bckup-title">
        <h2 className="text-center">EarthLink Online Backup</h2>
      </div>

      <Row className="text-center m-top-2">
        <Col span={8}>
          <div className="bckup-plans">
            <h2>50GB</h2>
            <h6>$5.95/mo</h6>
          </div>
        </Col>
        <Col span={8}>
          <div className="bckup-plans">
            <h2>250GB</h2>
            <h6>$6.95/mo</h6>
          </div>
        </Col>
        <Col span={8}>
          <div className="bckup-plans">
            <h2>500GB</h2>
            <h6>$9.95/mo</h6>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {/* <div className="call-common">
        <a href="tel:1-800-327-8454" className="blue-btn">Call 800-327-8454</a>
        </div> */}
        </Col>
      </Row>

      <Row className="m-top-2">
        <Col span={16}>
          <div className="online-plan-detail-sec">
            <div className="what-you-get gotback">
              <h3>We&apos;ve got your back</h3>
              <p>Automatically backup files to prevent data loss and easily restore and share data.</p>
            </div>
            <h5>Benefits:</h5>
            <ul className="blue-circle-lists">
              <li><i className="fas fa-check-circle"></i>Automatically backs up files to prevent data loss</li>
              <li><i className="fas fa-check-circle"></i>Backup & sync multiple devices to your personal storage space</li>
              <li><i className="fas fa-check-circle"></i>Conveniently view files online</li>
              <li><i className="fas fa-check-circle"></i>Share files with family, friends or colleagues</li>
              <li><i className="fas fa-check-circle"></i>Files are secure and encrypted</li>
              <li><i className="fas fa-check-circle"></i>Mobile apps for iOS and Android allow you to access your backed-up data</li>
              <li><i className="fas fa-check-circle"></i>Free 30-day trial*</li>
            </ul>
          </div>
        </Col>
        <Col span={8}>
          <div className="trial-section">
            <h3>Free 30-day trial</h3>
            <div className="contact-no-sec protect-contact">
              <a href="tel:1-800-327-8454" className="blue-button">
                <i className="fa fa-phone"></i>
                Call 800-327-8454
              </a>
              <span>To order today!</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default OnlineBackup
