import React from 'react';
import { SECURITY_TOOLS_URL } from '../../common/common';

const StaticVas = () => {
  return (
    <>
      <h2 className="text-center static-vas-prod-heading">We also offer…</h2>
      <div className="staic-vas-products">

        <div className="static-vas-inner">
          <div className="inner-vas-staic">
            <div className="vas_staic_head">
              <h3>Cyber Security</h3>
              <b>Starting at $7.95 / month</b>
            </div>

            <div className="vas_staic_body">
              <p className="vas_staic_txt">Multiple layers of protection for devices and online privacy for the whole family.</p>
              <ul className="vas_staic_list">
                <li>Real-time Threat Protection for your devices.</li>
                <li>Secure VPN to stay anonymous online.</li>
                <li>Password Manager, PC SafeCam, and Smart Firewall included.</li>
                <li>Dark Web Monitoring, Powered by LifeLock.</li>
              </ul>
            </div>
            <div className="vas_staic_foot">
              <div className="static-vas-button text-center">
                <a href={SECURITY_TOOLS_URL + 'cyber-safety/'} className="blue-button">Get Protect+</a>
              </div>
            </div>
          </div>
        </div>

        <div className="static-vas-inner">
          <div className="inner-vas-staic">
            <div className="vas_staic_head">
              <h3>Online & Data Backup</h3>
              <b>Starting at $5.95 / month</b>
            </div>

            <div className="vas_staic_body">
              <p className="vas_staic_txt">Automatically back up your files to prevent data loss.</p>
              <ul className="vas_staic_list">
                <li>Backup & sync multiple devices to your personal storage space.</li>
                <li>View and share files online.</li>
                <li>Files are secure and encrypted.</li>
                <li>Mobile apps for iOS and Android allow you to access your backed-up data.</li>
              </ul>
            </div>
            <div className="vas_staic_foot">
              <div className="static-vas-button text-center">
                <a href={SECURITY_TOOLS_URL + 'data-backup/'} className="blue-button">Get Backup</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default StaticVas;
