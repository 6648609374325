import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { getCallLogId } from '../../common/common';
import { useCartData, useServiceablitityData } from '../../hooks/order';
import { CheckScheduleAppt, setRtsAttemptCount, setRtsVisible } from '../../store/action/OrderAction';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '../../util/util';
const RtsModal = ({ handleCheckout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const callLogId = getCallLogId();
  const { internetPlan } = useCartData();
  const { isRtsVisible, scheduleLoading, rtsAttemptCount } = useServiceablitityData();

  const handleRetryScheduling = useCallback(async () => {
    dispatch(setRtsAttemptCount(rtsAttemptCount + 1));
    await dispatch(CheckScheduleAppt({
      userName: 'xmlc',
      serviceReference: internetPlan?.servRef,
      callLogId
    }))
      .then(res => {
        if (res.value.data.installWindow && res.value.data.installWindow.length > 0) {
          dispatch(setRtsVisible(false));
          handleCheckout();
          navigate(routesConfig.checkout);
        } else {
          if (rtsAttemptCount === 2) {
            dispatch(setRtsAttemptCount(0));
            dispatch(setRtsVisible(false));
            navigate(routesConfig.home)
          }
        }
      })
  }, [CheckScheduleAppt, dispatch, internetPlan, callLogId, scheduleLoading])

  const handleModalClose = useCallback(() => {
    dispatch(setRtsAttemptCount(0));
    dispatch(setRtsVisible(false));
    navigate(routesConfig.products);
  }, [setRtsVisible, dispatch])

  return (
    <Modal
      visible={isRtsVisible}
      maskClosable={false}
      width={520}
      footer={null}
      closable={false}
    >
      <p>
        Appointment scheduling not available at this time. Please <br />
        retry scheduling or try again later. You cannot proceed <br />
        with an order until scheduling is available.
      </p>
      <div className="result-compatible text-right">
        <Button
          htmlType="reset"
          className="modal-cancel-button"
          onClick={handleRetryScheduling}
          loading={scheduleLoading}
        >
          Retry Scheduling
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="modal-submit-button"
          onClick={handleModalClose}
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
};
export default RtsModal;
