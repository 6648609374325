import React from 'react';
import { useCartData, useLoading } from '../../hooks/order';
import loader from '../../images/loading-results.gif';
import { Carousel } from 'antd';
const contentStyle = {
  color: '#000',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const BackdropLoader = () => {
  const { scheduleLoading } = useLoading();
  const { isInternetAdded } = useCartData();

  return (
    <>
      {/* <div className={`main-outer ${loading ? 'ftr' : ''}`}> */}
      <div className="main-outer">
        <div className="outer-loader">
          <div className="loader-text"><img src={loader} alt="loading-animation" />
            <div className="carousel-outer">
              <Carousel autoplay effect="fade" autoplaySpeed={2000}>
                <div>
                  <div style={contentStyle} className="responsive-font-set"><i className="fa-solid fa-circle-check"></i>No data caps</div>
                </div>
                <div>
                  <div style={contentStyle} className="responsive-font-set"><i className="fa-solid fa-circle-check"></i>No throttling</div>
                </div>
                {scheduleLoading && isInternetAdded &&
                  <div>
                    <div style={contentStyle} className="responsive-font-set"><i className="fa-solid fa-circle-check"></i>Checking for available Internet
                      Installation appointments</div>
                  </div>}
              </Carousel>
            </div>
          </div>
        </div >
      </div>
    </>
  )
};

export const Loader = () => {
  return (
    <div className="loader-out">
      <div className="loader">
        <div className="mian-loader">
          <span className="first"></span>
          <span className="second"></span>
          <span className="third"></span>
        </div>
      </div>
    </div>

  )
};



